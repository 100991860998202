<template>
  <div class="chat-item">
    <el-avatar v-if="msgUserType === 'others'" class="user-head" :size="40" :src="talkInfo.headImgUrl">
      <img src="@/assets/avatar.png" alt=""/>
    </el-avatar>
    <div class="msg" :class="msgUserType">
      <span v-if="msgUserType === 'others'" class="chat-name">
        {{ name }} 用户  {{timeFormat(msgItem.msgTime, 'yyyy-mm-dd hh:MM:ss')}}
      </span>
      <span v-if="msgUserType === 'self'" class="chat-name" style="padding-right: 10px">
        {{timeFormat(msgItem.msgTime, 'yyyy-mm-dd hh:MM:ss')}}
      </span>
      <template v-if="msgUserType === 'self' && msgItem.msgKey && showMsgStatus">
        <span v-if="returnReadStatus" class="read-text">未读</span>
        <span v-else class="read-text">已读</span>
      </template>
      <template v-if="msgUserType === 'self' && !msgItem.msgKey && msgItem.type !== 0 && msgItem.type !== 11 && showMsgStatus">
        <span class="read-text">发送中</span>
      </template>
      <div v-if="msgItem.msgType === 1" class="msg-content" v-html="msgItem.msg"  :style="{background: msgItem.msgKey?msgUserType === 'self'?'#fff':'#528fff':'#528fff'}"></div>
      <div v-if="msgItem.apiorder_id" class="msg-content" v-html="orderInfo"></div>
      <el-image
        class="img"
        v-if="msgItem.msgType === 2"
        :src="`${msgItem.msg}`"
        :preview-src-list="[`${msgItem.msg}`]"
      >
      </el-image>
      <el-image
        class="img"
        v-if="msgItem.msgType === 12"
        :src="msgItem.msg"
        :preview-src-list="[msgItem.msg]"
      >
      </el-image>
    </div>
    <el-avatar v-if="msgUserType === 'self'" class="user-head" :size="40" :src="userInfo.avatar">
      <img src="@/assets/user-header.jpg" alt=""/>
    </el-avatar>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
  import {parseTime} from "@/utils";
  import timeFormat from "@/utils/timeFormat";
  import {IM_HOST} from "@/config";

  export default {
    name: 'chat-item',
    props: {
      name: {
        type: String,
      },
      msgUserType: {
        type: String,
        default: 'others',
      },
      msg: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'string',
      },
      msgItem:{
        type: Object,
        default: ()=>{},
      },
      showMsgStatus:{
        type:Boolean,
        default: true
      },
      talkInfo:{
        type: Object,
        default: ()=>{},
      }
    },
    data(){
      return {
        IM_HOST,
      }
    },
    computed:{
      ...mapState(['userInfo']),
      ...mapState('chat', ['readIDS','noreadMsgKeys']),
      ...mapGetters('chat', ['getNoreadKeys']),
      orderInfo(){
        return `订单号:&nbsp;&nbsp;${this.msgItem.apiorder_id};<br/>
          金额:&nbsp;${this.msgItem.order_money};<br/>
          过期时间：&nbsp;&nbsp;${parseTime(this.msgItem.overtime)}`
      },
      returnReadStatus(){
        const keys = this.noreadMsgKeys[this.msgItem.order_id]
        if(!keys || !keys.length) return false
        return keys.includes(this.msgItem.msgKey);
      }
    },
    methods:{
      timeFormat,
    }
  }
</script>

<style lang="scss" scoped>
  .chat-item {
    display: flex;
    margin-bottom: 20px;
  }
  .user-head {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .msg {
    flex: 1;

    .chat-name {
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
      color: #999999;
    }
    .add-time{
      font-size: 12px;
      color: #ccc;
    }
    .img {
      max-width: 150px;
    }
    &.others {
      .msg-content {
        border-radius: 0 30px 30px 30px;
        background-color: #528fff;
        color: #fff;
      }
    }
    &.self {
      text-align: right;
      .msg-content {
        border-radius: 30px 0 30px 30px;
        background-color: #fff;
        color: #333;
        margin-right: 10px;
        text-align: left;
      }
    }
    .msg-content {
      padding: 10px 20px;
      max-width: 75%;
      font-size: 14px;
      display: inline-block;
    }
  }
  .read-text{
    display: inline-block;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
    transform: scale(0.8);
    color: #2F88FF;
  }
</style>

<style lang="scss">
  .msg-content {
    img {
      max-width: 50%;
    }
  }
</style>

import {getChatHistory, getChatList} from '@/api/chat.js'
import {parseTime} from '@/utils/index.js'
import localforage from "localforage";
import Vue from "vue"
import {IDLE_TIME} from "@/config";
import store from "../../store"

const state = () => ({
  socketStatus:false, // socket连接状态
  workStatus:false, // 工作状态
  list: [],
  msgList: [],
  imMsgList: {},
  admin: false,
  type: 1, // 0是失效1待支付2已支付3付款码4聊天记录
  currentTalkId: 0,
  loading1: false, // 带聊天列表加载状态
  msgNum:0, // 用于消息变动触发，无其他作用
  reminder:true, // 是否开启提醒
  lastChatId:[], // 发送消息后返回的最新一个chat_id
  readIDS:{}, // 已读id数据
  chatNoReadList:{},
  timeoutStatus:false, // 是否未处理订单超时状态
  timer:null, // 处理超时定时器
  countdown:0, // 计时读秒
  changeOnline:false, // 需要切换在线状态时使用
  noreadMsgKeys:{}, // 发送出去的消息未读消息key
  noReadList:{},// 收到消息未读的key
  badgeShow:false, // 新消息显示提醒图标
  page:1,
  talkListCount:0, // 数据总条数
})

const getters = {
  admin: (state) => state.admin,
  getTalkList: (state)=>{
    const cloneList = JSON.parse(JSON.stringify(state.list))
    return cloneList.sort((a,b) => new Date(b.add_time) - new Date(a.add_time))
  },
  getMsgs: (state) => state.msgList.sort(item=> new Date(item.msgTime) - new Date(item.msgTime)),
  getReadIds:(state)=>{
    if(state.readIDS && JSON.stringify(state.readIDS) !== '{}') return state.readIDS
    localforage.getItem('chatRead').then(r=>{
      state.readIDS = r||{}
    })
    return state.readIDS
  },
  getNoredNum:(state)=>(talk_id)=>{
    const chatids = state.chatNoReadList[talk_id]
    return chatids?chatids.length:0
  },
}

const mutations = {
  setWorkStatus(state,status){
    state.workStatus = status
  },
  setBadgeShow(state, status){
    state.badgeShow = status
  },
  setOnlineList(state, data){
    state.list = state.list.map(item=>{
      console.log(item.uid, data.user)
      if(String(item.uid) === String(data.user)){
        item.online = data.onlineStatus
        return item
      }
      return item
    })
  },
  setImMsgList(state, msgItem){
    console.log(msgItem)
    if(!msgItem.order_id) return
    const msgKey = state.imMsgList[msgItem.order_id]
    if(msgKey){
      state.imMsgList[msgItem.order_id].push(msgItem)
    }else{
      state.imMsgList[msgItem.order_id] = []
      state.imMsgList[msgItem.order_id].push(msgItem)
    }
    // 保存到发送消息未读keys
    console.log(msgItem.formUid, store.state.userInfo.service_id)
    if(msgItem.formUid === store.state.userInfo.service_id){
      const noReadKey = state.noreadMsgKeys[msgItem.order_id]
      if(noReadKey){
        noReadKey.push(msgItem.msgKey)
        Vue.set(state.noreadMsgKeys, msgItem.order_id, noReadKey)
      }else{
        Vue.set(state.noreadMsgKeys, msgItem.order_id, [msgItem.msgKey])
      }
    }
    localforage.setItem('imMsgList', state.imMsgList)
    localforage.setItem('noreadMsgKeys', state.noreadMsgKeys)
    // 菜单显示消息图标
    if(state.type === 1) state.badgeShow = false
    if(state.type !== 1){
      if(msgItem.extend && msgItem.extend.newOrder && msgItem.extend.newOrder === 1){
        state.badgeShow = true
      }else{
        localforage.getItem('chatList-1').then(res=>{
          const extend = msgItem.extend
          if(res && res.length > 0 && (!extend || !extend.overOrder || extend.overOrder !== 1)){
            const filter = res.filter(item=>{
              return item.order_id === msgItem.order_id
            })
            if(filter && filter.length > 0) state.badgeShow = true
          }
        })
      }
    }
  },
  // 处理发出消息未读key
  setReadKeys(state,params){
    const {order_id, msgKeyList} = params
    if(msgKeyList.length === 0) return
    const keys = state.noreadMsgKeys[order_id]
    if(keys){
      const noreadMsgKeys = keys.filter(item=>{
        return !msgKeyList.includes(item)
      })
      Vue.set(state.noreadMsgKeys, order_id, noreadMsgKeys)
      localforage.setItem('noreadMsgKeys', state.noreadMsgKeys)
    }
  },
  resetMsgList(state, msgList){
    state.imMsgList = msgList
  },
  resetMyNoReadMsgKeys(state, keyList){
    state.noreadMsgKeys = keyList
  },
  SOCKET_STATUS(state, status) {
    state.socketStatus = status
  },
  setChangeOnline(state, status){
    state.changeOnline = status
  },
  // 存储接收消息未读key
  setNoReadList(state,msgData){
    if(!msgData.formUid) return
    if(!msgData.order_id) return
    const userNoRead = state.noReadList[msgData.order_id]
    if(userNoRead){
      userNoRead.push(msgData.msgKey)
      Vue.set(state.noReadList, msgData.order_id, userNoRead)
    }else{
      Vue.set(state.noReadList, msgData.order_id, [msgData.msgKey])
    }
    localforage.setItem('noReadList', state.noReadList)
  },
  // 清除接收消息未读key
  clearUserNoRead(state, params){
    if(!params.order_id) return
    Vue.set(state.noReadList, params.order_id, [])
    localforage.setItem('noReadList', state.noReadList)
  },
  resetUserNoReadKeys(state, keys){
    state.noReadList = keys
  },
  // 订单处理超时计时器
  orderHandleTime(state){
    if(state.timer && state.countdown === 0) {
      console.log('已有监听', state.countdown, state.timeoutStatus)
      return
    }else{
      if(state.timer){
        clearInterval(state.timer)
        state.timer = null
      }
    }
    state.timer = setInterval(()=>{
      state.countdown += 5
      state.timeoutStatus = false
      console.log('处理监听开始', state.countdown, state.timeoutStatus)
      if(state.countdown >= IDLE_TIME){
        state.timeoutStatus = true
        clearInterval(state.timer)
        state.timer = null
      }
    },5000)
  },
  reloadOrderHandleTime(state){
    if(state.timer){
      clearInterval(state.timer)
      state.timer = null
    }
    state.timer = null
    state.timeoutStatus = false
    state.countdown = 0
    state.changeOnline = false
  },
  resetChatNoReadList(state,data){
    state.chatNoReadList = data
  },
  setChatNoReadList(state, msgItem){
    const talk_id = msgItem.data && msgItem.data.talk_id?msgItem.data.talk_id: msgItem.talk_id
    if(!talk_id) return
    const noReadItem = state.chatNoReadList[talk_id]
    const chat_id = msgItem.data && msgItem.data.chat_id ? msgItem.data.chat_id : msgItem.chat_id
    if(noReadItem && noReadItem.length){
      noReadItem.push(chat_id)
      Vue.set(state.chatNoReadList, talk_id, noReadItem)
    }else{
      state.chatNoReadList[talk_id] = [chat_id]
    }
    localforage.setItem('chatNoReadList', state.chatNoReadList).then(res=>{
      console.log('chatNoReadList res', res)
    })
  },
  clearNoReadNum(state, talk_id){
    Vue.set(state.chatNoReadList, talk_id, null)
    localforage.setItem('chatNoReadList', state.chatNoReadList).then(res=>{
      console.log('chatNoReadList res', res)
    })
  },
  setreminder(state,status){
    state.reminder = status
  },
  setonlineStatus(state,data){
    const talkIndex = state.list.findIndex(item=> item.userinfo && item.userinfo.uid === data.uid)
    if(talkIndex !== -1){
      const item = Object.assign({},state.list[talkIndex])
      item.userinfo.is_online = data.online
      state.list.splice(talkIndex,1,item)
    }
  },
  setTalkId(state, payload) {
    state.currentTalkId = payload
    const msgs = state.imMsgList[payload]
    state.msgList = []
    if(msgs && msgs.length){
      state.msgList = msgs
    }
  },
  msgChange(state){
    state.msgNum = state.msgNum+1
  },
  setType(state, payload) {
    state.type = payload
    state.page = 1
  },
  setList(state, payload) {
    state.list = payload.map( item =>{
      item.online = false
      return item
    })
  },
  setHistory(state, payload) {
    if (payload && payload.length) {
      const tmp = payload.map((item) => {
        const type = item.role.type == 'USER' ? 'others' : 'self'
        const tmp = {
          id: item.chat_id,
          msgUserType: type,
          msg: item.msn || '空数据',
        }
        if (item.msn_type == 3) {
          tmp.msg = item.msn_info.att_dir
          tmp.type = 'img'
        } else if (item.msn_type == 11) {
          tmp.msg = `订单号:&nbsp;&nbsp;${item.msn_info.apiorder_id};<br/>
          金额:&nbsp;${item.msn_info.order_money};<br/>
          过期时间：&nbsp;&nbsp;${parseTime(item.msn_info.overtime)}`
          tmp.type = 'string'
        }
        return tmp
      })
      state.msgList = tmp.sort((a, b) => a.id - b.id)
    }
  },
  clearHistory(state) {
    state.msgList = []
    state.currentTalkId = ''
  },
  // 客户回复信息
  pushClentMsg(state, item) {
    console.log(item)
    if (!state.currentTalkId) {
      return
    }
    console.log('item is', item)
    const type = item.msn_type
    console.log('type is', type)
    const tmp = {
      id: item.chat_id,
      msgUserType: 'others',
      msg: item.msn || '空数据',
    }
    if (type == 3) {
      tmp.msg = item.msn.att_dir
      tmp.type = 'img'
    }

    state.msgList.push(tmp)
  },
  // 填充客服发送的信息
  pushMsg(state, item) {
    console.log('push item', item)
    const tmp = {
      id: item.chat_id || Date.now(),
      msgUserType: 'self',
      msg: item.msn || '空数据',
      type: item.type,
    }
    state.msgList.push(tmp)
    // state.list = [...state.list]
  },
  updateNum(state, data) {
    const id = data.talk_id
    const index = state.list.findIndex((item) => item.talk_id === id)
    if (index != -1) {
      state.list[index].no_read = data.num
    }
  },
  uploadLoading(state, payload) {
    state.loading1 = payload
  },
  delCurrentChat(state) {
    console.log('删除当前聊天')
    const index = state.list.findIndex(
      (item) => item.talk_id === state.currentTalkId
    )
    console.log('index is', index)
    if (index > -1) {
      state.list.splice(index, 1)
      state.currentTalkId = ''
      state.msgList = []
    }
  },
  talkListSort(state, msgData){
    const index = state.list.findIndex((item) => item.talk_id === msgData.talk_id)
    if(index !== -1){
      const clontTalkItem = JSON.parse(JSON.stringify(state.list[index]))
      clontTalkItem.last_chat.add_time = new Date()
      state.list.splice(index,1,clontTalkItem)
    }
  },
  setLastChatId(state, chatData){
    if(state.lastChatId.length >= 50){
      state.lastChatId.splice(0, 20)
    }
    state.lastChatId.push({
      chat_id: chatData.chat_id,
      talk_id: chatData.talk_id
    })
  },
  setreadIDS(state, readData){ // 设置收到用户已读
    if(!readData || !readData.talk_id) return
    const find = state.readIDS[`read-${readData.talk_id}`]
    if(find){
      state.readIDS[`read-${readData.talk_id}`].push(readData.chat_id)
    }else{
      state.readIDS[`read-${readData.talk_id}`] = [readData.chat_id]
    }
    localforage.setItem('chatRead', state.readIDS)
  },
  resetChatReadState(state, data){
    state.readIDS = data
  },
  setPage(state, page){
    state.page = page
  },
  setTlakListCount(state, count){
    state.talkListCount = count
  }
}

const actions = {
  async updateTalkList({ commit, state, dispatch }, data) {
    console.log('data is', data)
    // debugger
    const id = data.talk_id
    const index = state.list.findIndex((item) => item.talk_id === id)
    if (index !== -1) {
      // state.list[index].num = data.num
      commit('updateNum', data)
      const clontTalkItem = JSON.parse(JSON.stringify(state.list[index]))
      clontTalkItem.last_chat.add_time = new Date()
      state.list.splice(index,1, clontTalkItem)
      console.log('clontTalkItem',clontTalkItem)
    } else {
      dispatch('getChatLists')
      commit('orderHandleTime')
    }
  },
  // 获取聊天列表
  async getChatLists({ commit, state}) {
    try {
      commit('uploadLoading', true)
      const params = {
        status: state.type? state.type : 3,
      }
      if(state.type === 0 || state.type === 2){
        params.page = state.page
        params.limit = 20
      }
      console.log(state.type)
      const res = await getChatList(params)
      //console.log('聊天列表', res)
      if (res && res.code === 0) {
        if(res.data){
          if(res.count) commit('setTlakListCount', res.count)
          commit('setList', res.data)
          if(state.type === 1) await localforage.setItem('chatList-1', res.data)
        }else{
          commit('setList', [])
          if(state.type === 1) await localforage.setItem('chatList-1', [])
        }
      }
      setTimeout(() => {
        commit('uploadLoading', false)
      }, 500)
    } catch (err) {
      console.error(err)
    }
  },
  // 获取聊天记录
  async getChatHistory({ commit, state }) {
    try {
      const res = await getChatHistory(state.currentTalkId)
      console.log('获取聊天记录', res)
      if (res && res.code === 200) {
        commit('setHistory', res.data.list)
      }
    } catch (err) {
      console.error(err)
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }

const dev = process.env.NODE_ENV === "development"

export const BASE_URL = 'https://ppay.ruanwenfuwu.com/api'  // 订单服务接口域名

export const BASE_WS_URL = dev?'ws://127.0.0.1:8899':'wss://msim.webtest.vip'  // SOCKETIO服务域名

export const IM_HOST = dev?"http://127.0.0.1:8899":'https://msim.webtest.vip'  // IM服务接口域名

export const IDLE_TIME = 60  // 有订单进来未处理时多久提示下线，单位：秒

export const IDLE_COUNTDOWN = 30  // 空闲下线倒计时秒数，单位：秒

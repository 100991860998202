import request from '../utils/axios.js'
import {IM_HOST} from "@/config";

// 客服登录
export function login(data) {
  return request({
    // url: '/serviceapi/login',
    url: '/Service/pollingLogin',
    method: 'POST',
    data,
  })
}

// 客服登出
export function logout(data) {
  return request({
    // url: '/serviceapi/login',
    url: '/Service/logout',
    method: 'get',
    data,
  })
}

// 获取客服身份详情
export function getUserInfo() {
  return request({
    url: '/Service/servicedetails',
    method: 'GET',
  })
}
// 修改客服在线状态
export function changeUserSate(data) {
  return request({
    baseURL: IM_HOST,
    url: '/v1/user/changeWorkStatus',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    data,
  })
}

// 修改客服信息
export function serviceupdate(data) {
  return request({
    url: '/Service/serviceupdate',
    method: 'POST',
    data,
  })
}

<template>
  <div class="order-search">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item label="订单号：">
        <el-input v-model="form.orderId" placeholder="请输入KF开头或VT开头的订单号" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="getOrderInfo">查询</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div style="padding-top: 20px">
      <el-descriptions title="" border :column="2" v-if="JSON.stringify(orderInfo) !== '{}'">
<!--        <el-descriptions-item label="支付系统订单ID">{{orderInfo.apiorder_id}}</el-descriptions-item>-->
        <el-descriptions-item label="商户订单ID">{{orderInfo.order_id}}</el-descriptions-item>
        <el-descriptions-item label="订单金额">{{orderInfo.order_money}}</el-descriptions-item>
        <el-descriptions-item label="支付金额">{{orderInfo.order_price}}</el-descriptions-item>
        <el-descriptions-item label="商户ID">{{orderInfo.user_id}}</el-descriptions-item>
        <el-descriptions-item label="支付类型">{{orderInfo.type==='artificial_recharge'?'人工代付':orderInfo.type}}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{parseTime(orderInfo.sub_time)}}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{returnOrderStatus(orderInfo.status)}}</el-descriptions-item>
<!--        <el-descriptions-item label="支付成功后跳转">{{orderInfo.return_url}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="支付成功后通知">{{orderInfo.notify_url}}</el-descriptions-item>-->
        <el-descriptions-item label="有效时间">{{parseTime(orderInfo.overtime)}}</el-descriptions-item>
<!--        <el-descriptions-item label="话题ID">{{orderInfo.talk_id}}</el-descriptions-item>-->
      </el-descriptions>
      <el-empty v-else description="无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import {get_pay_service_order_list} from "@/api/code";
import {parseTime} from "@/utils/index"

export default {
  data(){
    return {
      form:{},
      orderInfo:{},
    }
  },
  methods:{
    parseTime,
    async getOrderInfo(){
      if(!this.form.orderId){
        this.$message.warning('请输入订单号')
        return
      }
      try{
        const res = await get_pay_service_order_list({
          order_id: this.form.orderId
        })
        if(res.data.list && res.data.list.length){
          this.orderInfo = res.data.list[0]
        }
      }catch (e) {
        console.log(e)
      }
    },
    returnOrderStatus(status){
      const orderStatus = parseInt(status)
      switch (orderStatus){
        case 0:
          return '已失效'
        case 1:
          return '支付中'
        case 2:
          return '支付成功'
        default:
          return '未知'
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>

<style>
.order-search .el-divider--horizontal{
  margin: 0;
}
</style>

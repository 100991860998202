<template>
  <div class="home">
    <div class="header">
      <template v-if="userInfo.roles && userInfo.roles.length">
        <img style="width: 40px; height: 40px" src="@/assets/avatar.png"/>
      </template>
      <template v-else>
        <div class="online-status">
          <span class="label">值班状态：</span>
          <span :class="['online-text',workStatus === 1 ? '' : 'a']">
            {{workStatus === 1 ? '值班中' : '已下班' }}
          </span>
          <span class="label" style="margin-left: 20px">IM服务状态：</span>
          <span :class="['online-text', socketStatus ? '' : 'abnormal']">
            {{ socketStatus ? '正常' : '异常' }}
          </span>
        </div>
      </template>
      <div class="tools" v-if="!userInfo.roles || !userInfo.roles.length">
        <div class="audio">
          <el-switch
            :value="reminder"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="switchChange"
          >
          </el-switch>
          <span>新订单声音提醒</span>
        </div>
        <div class="clear-history" @click="clearHistory">
          <img src="@/assets/clear-icon.png" alt="">
          <span>一键清理本地聊天记录</span>
        </div>
      </div>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ (userInfo && userInfo.nickname) || '游客' }}
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="updateData">修改资料</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="main">
      <div class="menus">
        <template v-if="userInfo && userInfo.roles && userInfo.roles.length">
          <div class="menu-list">
            <div
              :class="['menu-item', type == 4 ? 'active' : '']"
              @click="handleChangeList(4)"
            >
              <!-- <i class="el-icon-menu"></i> -->
              <i class="el-icon el-icon-chat-dot-square"></i>
              <span>聊天记录</span>
            </div>
          </div>
          <div class="menu-list">
            <div
              :class="['menu-item', type == 5 ? 'active' : '']"
              @click="handleChangeList(5)"
            >
              <!-- <i class="el-icon-menu"></i> -->
              <i class="el-icon el-icon-data-line"></i>
              <span>数据看板</span>
            </div>
          </div>
          <div class="menu-list">
            <div
              :class="['menu-item', type == 7 ? 'active' : '']"
              @click="handleChangeList(7)"
            >
              <!-- <i class="el-icon-menu"></i> -->
              <i class="el-icon el-icon-user"></i>
              <span>在线客服列表</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="start-work">
            <span v-if="workStatus === 2" @click="changeWorkStatus(1)">开始值班</span>
            <span v-if="workStatus === 1" @click="changeWorkStatus(2)">我要下班</span>
          </div>
          <div class="menu-list">
            <div
              :class="['menu-item', type == 1 ? 'active' : '']"
              @click="handleChangeList(1)"
            >
              <!-- <i class="el-icon-menu"></i> -->
              <img class="icon" src="@/assets/all.png" alt=""/>
              <span>待处理订单
                <el-badge v-if="badgeShow" value="new" class="item" style="margin-bottom: -9px"></el-badge>
              </span>

            </div>
            <div
              :class="['menu-item', type == 2 ? 'active' : '']"
              @click="handleChangeList(2)"
            >
              <!-- <i class="el-icon-finished"></i> -->
              <img
                class="icon"
                src="@/assets/finsh.png"
                style="width: 28px; height: 16px"
                alt=""
              />
              <span>已完成</span>
            </div>
            <div
              :class="['menu-item', type == 0 ? 'active' : '']"
              @click="handleChangeList(0)"
            >
              <!-- <i class="el-icon-document-delete"></i> -->
              <img
                class="icon"
                src="@/assets/cancel.png"
                style="width: 26px; height: 29px"
                alt=""
              />
              <span>已失效</span>
            </div>
            <div
              :class="['menu-item', type == 3 ? 'active' : '']"
              @click="handleChangeList(3)"
            >
              <!-- <i class="el-icon-menu"></i> -->
              <img class="icon" src="@/assets/qrcode-icon.png" alt=""/>
              <span>收款码管理</span>
            </div>
            <div
              :class="['menu-item', type == 6 ? 'active' : '']"
              @click="handleChangeList(6)"
            >
              <i class="icon el-icon-wallet" style="font-size: 26px"></i>
              <!--              <img class="icon" src="@/assets/qrcode-icon.png" alt=""/>-->
              <span>订单管理</span>
            </div>
            <div class="menu-list">
              <div
                :class="['menu-item', type == 5 ? 'active' : '']"
                @click="handleChangeList(5)"
              >
                <!-- <i class="el-icon-menu"></i> -->
                <i class="el-icon el-icon-data-line"></i>
                <span>数据看板</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template v-if="type !== 4">
        <div
          class="content"
        >
          <router-view></router-view>
        </div>
      </template>
      <template v-else>
        <div class="content">
          <router-view></router-view>
        </div>
      </template>
    </div>
    <clear-loca-history-modal v-if="clearLocaHistoryModalShow" @close="clearLocaHistoryModalShow=false"/>
    <!--  修改用户资料  -->
    <change-user v-if="changeUserModel" @close="changeUserModel=false"/>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex'
import {changeUserSate, logout} from '@/api/login.js'
import ClearLocaHistoryModal from "@/components/clearLocaHistoryModal.vue"
import ChangeUser from "@/components/changeUser.vue"
import {initWS} from "@/utils/socketio";


export default {
  name: 'Home',
  components: {ClearLocaHistoryModal, ChangeUser},
  data() {
    return {
      loading: true,
      isOnline: sessionStorage.getItem('isOnline') || false,
      time: 0,
      clearLocaHistoryModalShow: false,
      changeUserModel: false,
      userOff: false,
      changeStateTimer: null,
      changeStatetime: 10,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('chat', ['type', 'currentTalkId', 'reminder', 'lastChatId', 'readIDS', 'changeOnline','socketStatus','workStatus','badgeShow']),
    ...mapGetters('chat', ['getReadIds']),
    online() {
      if (this.userInfo && this.userInfo.online === 1) {
        return this.userInfo.online === 1
      } else {
        return false
      }
    },
  },
  created() {
    this.getUserInfo()
    this.getChatLists()
    initWS()
  },
  async mounted() {
    await changeUserSate({
      isWork: 2
    })
    this.setWorkStatus(2)
  },
  watch: {
    userInfo: {
      handler: function (val) {
        if (!val) return
        console.log(val.roles)
        this.$nextTick(function () {
          if (val.roles && val.roles.length) {
            this.handleChangeList(4)
            this.loading = false
          } else {
            const currRoule = this.$route.path
            if (currRoule !== '/home') this.$router.push('/home')
          }
        })
      },
      deep: true,
      immediate: true
    },
    // 监听当用户未处理订单达到触发下线条件时，设置用户下线为非值班状态
    changeOnline(val) {
      if (val && this.online) {
        sessionStorage.setItem('isOnline', 'false')
        this.reloadOrderHandleTime()
        this.$socket.emit('close')
        this.$socket.disconnect()
        this.$router.push('/')
      }
    }
  },
  beforeDestroy() {
    if (this.time) {
      clearInterval(this.time)
    }
    if (this.changeStateTimer) {
      clearInterval(this.changeStateTimer)
      this.changeStateTimer = null
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    ...mapActions('chat', [
      'getChatLists',
      'getChatHistory',
      'updateTalkList',
    ]),
    ...mapMutations('chat', [
      'setType',
      'pushClentMsg',
      'setTalkId',
      'msgChange',
      'setreminder',
      'setonlineStatus',
      'talkListSort',
      'reloadOrderHandleTime',
      'setLastChatId',
      'setreadIDS',
      'setChatNoReadList',
      'setWorkStatus',
      'setBadgeShow'
    ]),
    ...mapMutations(['setToken']),
    changeWorkStatus(status) {
      if(!this.socketStatus){
        this.$message.warning(`当前聊天服务未正常连接，请刷新重试`)
        return
      }
      if (this.changeStateTimer) {
        this.$message.warning(`切换太频繁，等待${this.changeStatetime}秒后再试。`)
        return
      }
      this.changeStateTimer = setInterval(() => {
        this.changeStatetime = this.changeStatetime - 1
        if (this.changeStatetime === 1) {
          clearInterval(this.changeStateTimer)
          this.changeStateTimer = null
          this.changeStatetime = 10
        }
      }, 1000)
      this.userOff = true
      this.changeState(status)
      this.userOff = status !== 1;
    },
    switchChange(e) {
      this.setreminder(e)
    },
    clearHistory() {
      this.clearLocaHistoryModalShow = true
    },
    // 修改客服状态
    async changeState(status=2) {
      if (status === undefined) return
      if (this.userInfo && this.userInfo.roles && this.userInfo.roles.length && !this.userOff) return
      try {
        const params = {isWork: status}
        const res = await changeUserSate(params)
        console.log('修改在线状态', !this.online)
        sessionStorage.setItem('isOnline', String(status))
        this.$message.success(res.msg)
        this.setWorkStatus(status)
        await this.getUserInfo()
        this.loading = !this.online;
        this.reloadOrderHandleTime()
      } catch (error) {
        console.error(error)
      }
    },
    // 退出
    async handleLogout() {
      try {
        await logout()
        this.setToken('')
        this.$socket.emit('close')
        this.$socket.disconnect()
        localStorage.removeItem('token')
        sessionStorage.removeItem('isOnline')
        await this.$router.push('/')
      } catch (error) {
        console.error(error)
      }
    },
    handleCommand(key) {
      console.log('key is', key)
      if (key === 'logout') {
        this.reloadOrderHandleTime()
        this.handleLogout()
      }
      if (key === 'updateData') {
        this.changeUserModel = true
      }
    },
    // 切换列表状态
    handleChangeList(state) {
      this.setType(state)
      this.setTalkId('')
      const currRoule = this.$route.path
      if(state === 1){
        this.setBadgeShow(false)
      }
      if (state === 0 || state === 1 || state === 2) {
        if (currRoule !== '/home') this.$router.push('/home')
        this.getChatLists()
      } else {
        if (state === 3 && currRoule !== '/codeManageMent') this.$router.push('/codeManageMent')
        if (state === 4 && currRoule !== '/chatLog') this.$router.push('/chatLog')
        if (state === 5 && currRoule !== '/dataBoard') this.$router.push('/dataBoard')
        if (state === 6 && currRoule !== '/orderManagement') this.$router.push('/orderManagement')
        if (state === 7 && currRoule !== '/onlineService') this.$router.push('/onlineService')
      }
    },

  },

}
</script>

<style lang="scss" scoped>
.header {
  height: 68px;
  background: #151b1f;
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .online-status {
    font-size: 14px;
    color: #fff;

    .online-text {
      color: #00f96b;
    }

    .a {
      color: #a1a1a1;
    }
    .abnormal{
      color: #e74444;
    }
  }

  .tools {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-content: center;

    span {
      display: inline-block;
      height: 20px;
      padding-left: 10px;
    }

    .audio {
      color: #969DC4;
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .clear-history {
      color: #969DC4;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin: 0 40px;
      background: #262C4E;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 22px;
        height: 27px;
      }
    }
  }

  .el-dropdown-link {
    color: #fff;
  }
}

.main {
  display: flex;

  .menus {
    flex: 0 0 190px;
    height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #191d35;

    .start-work {
      width: 96px;
      height: 34px;
      background: #f5f6f8 linear-gradient(135deg, #2f88ff 0%, #43ccf8 100%);
      color: #fff;
      font-size: 14px;
      border-radius: 16px;
      margin: 18px 0;
      text-align: center;
      line-height: 34px;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .menu-list {
      height: 54px;
      line-height: 54px;
      width: 190px;

      .menu-item {
        text-align: left;
        padding-left: 20px;
        color: #969dc4;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background: #262a3f;
        }

        &.active {
          border-left: 3px solid #2f88ff;
          background: #262c4e;
          color: #fff;
        }

        i {
          margin-right: 20px;
          font-size: 18px;
        }

        .icon {
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  .content {
    flex: 1;
  }
}

.content {
  height: calc(100vh - 68px);
  padding: 26px;
  box-sizing: border-box;
  overflow-y: auto;
  background: #f5f6f8;
}
</style>

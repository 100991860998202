<template>
  <el-dialog
    title="下线提醒"
    :visible.sync="dialogVisible"
    width="600px"
    :center="true"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <p class="content">
      <span style="font-size: 16px">有用户正在等待业务处理，请尽快回复和处理，否则将会被自动下线</span>
      <br/>
      <span class="time">倒计时：<b>{{IDLE_COUNTDOWN}}</b> s</span>
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" type="primary">知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {IDLE_COUNTDOWN, IDLE_TIME} from "@/config";
import {mapState, mapMutations} from "vuex";

export default {
  data(){
    return {
      dialogVisible: false,
      IDLE_TIME,
      IDLE_COUNTDOWN,
      timer:null
    }
  },
  computed:{
    ...mapState('chat',['timeoutStatus'])
  },
  watch:{
    timeoutStatus(val){
      if(val){
        this.dialogVisible = true
        this.IDLE_COUNTDOWN = IDLE_COUNTDOWN
        if(this.timer){
          clearInterval(this.timer)
          this.timer = null
        }
        this.startTime()
      }
      if(!val && this.timer){
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  mounted() {

  },
  methods:{
    ...mapMutations('chat',['reloadOrderHandleTime','setChangeOnline']),
    startTime(){
      this.timer = setInterval(()=>{
        this.IDLE_COUNTDOWN--
        if(this.IDLE_COUNTDOWN === 0){
          clearInterval(this.timer)
          this.timer = null
          this.IDLE_COUNTDOWN = IDLE_COUNTDOWN
          this.setChangeOnline(true)
          this.dialogVisible = false
        }
      },1000)
    }
  },
  beforeDestroy() {
    if(this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
</script>

<style scoped lang="scss">
.content{
  text-align:center;
  display: flex;
  flex-direction: column;
  .time{
    margin-top: 20px;
    font-size: 18px;
    padding-bottom: 30px;
    b{
      color: red;
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo } from '@/api/login'
import socketMsg from './modules/socketMsg.js'
import chat from './modules/chat.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: {},
  },
  getters: {
    kefuToken(state) {
      return state.token || localStorage.getItem('token')
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
      localStorage.setItem('token', payload)
    },
    setInfo(state, payload) {
      state.userInfo = payload
    },
    setOnline(state, status){
      const userInfo = state.userInfo
      userInfo.online = status
      state.userInfo = userInfo
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      const res = await getUserInfo()
      //console.log('用户信息：', res)
      if (res && res.code === 200) {
        commit('setInfo', res.data)
        return Promise.resolve(res.data)
      }
      return Promise.reject(res)
    },
  },
  modules: {
    socketMsg,
    chat,
  },
})

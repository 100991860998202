<template>
  <div class="chat-item">
    <div class="msg" :class="msgUserType">
      <div
        v-if="msgItem.msgType === 1"
        class="msg-content"
        style="background: #fff"
      >
        <p>您好，请选择您的支付方式</p>
        <div class="pay-methods">
          <p><img src="@/assets/alipay.png" alt=""><span>支付宝充值</span></p>
          <p><img src="@/assets/wechat-pay.png" alt=""><span>微信充值</span></p>
        </div>
      </div>
    </div>
    <el-avatar v-if="msgUserType === 'self'" class="user-head" :size="40" :src="userInfo.avatar">
      <img src="@/assets/user-header.jpg" alt=""/>
    </el-avatar>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {parseTime} from "@/utils";
import timeFormat from "@/utils/timeFormat";
import {IM_HOST} from "@/config";

export default {
  name: 'chat-item',
  props: {
    name: {
      type: String,
    },
    msgUserType: {
      type: String,
      default: 'others',
    },
    msg: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'string',
    },
    msgItem:{
      type: Object,
      default: ()=>{},
    },
    showMsgStatus:{
      type:Boolean,
      default: true
    },
    talkInfo:{
      type: Object,
      default: ()=>{},
    }
  },
  data(){
    return {
      IM_HOST,
    }
  },
  computed:{
    ...mapState(['userInfo']),
    ...mapState('chat', ['readIDS','noreadMsgKeys']),
    ...mapGetters('chat', ['getNoreadKeys']),
    orderInfo(){
      return `订单号:&nbsp;&nbsp;${this.msgItem.apiorder_id};<br/>
          金额:&nbsp;${this.msgItem.order_money};<br/>
          过期时间：&nbsp;&nbsp;${parseTime(this.msgItem.overtime)}`
    },
    returnReadStatus(){
      const keys = this.noreadMsgKeys[this.msgItem.order_id]
      if(!keys || !keys.length) return false
      return keys.includes(this.msgItem.msgKey);
    }
  },
  methods:{
    timeFormat,
  }
}
</script>

<style lang="scss" scoped>
.chat-item {
  display: flex;
  margin-bottom: 20px;
}
.user-head {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.msg {
  flex: 1;
  margin-bottom: 30px;
  .chat-name {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    color: #999999;
  }
  .add-time{
    font-size: 12px;
    color: #ccc;
  }
  .img {
    max-width: 150px;
  }
  &.others {
    .msg-content {
      border-radius: 0 30px 30px 30px;
      background-color: #528fff;
      color: #fff;
    }
  }
  &.self {
    text-align: right;
    .msg-content {
      border-radius: 30px 0 30px 30px;
      background-color: #fff;
      color: #333;
      margin-right: 10px;
      text-align: left;
    }
  }
  .msg-content {
    padding: 10px 20px;
    max-width: 75%;
    font-size: 14px;
    display: inline-block;
  }
}
.read-text{
  display: inline-block;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  transform: scale(0.8);
  color: #2F88FF;
}
.pay-methods{
  display: flex;
  margin-top: 20px;
  p{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 100px;
      margin-bottom: 5px;
    }
    span{
      font-size: 12px;
    }
  }
}
</style>

<style lang="scss">
.msg-content {
  img {
    max-width: 50%;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login'
import ChatPage from '@/views/ChatPage'
import CodeManageMent from "@/views/codeManagement.vue"
import ChatLog from "@/views/chatLog.vue"
const DataBoard = ()=> import('../views/dataBoard.vue')
const orderManagement = ()=> import('../views/orderManagement.vue')
const onlineService = ()=> import("../views/onlineService.vue")

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect:"/",
    children: [
      {
        path: '/',
        name: 'ChatPage',
        component: ChatPage,
      },
      {
        path: '/codeManageMent',
        name: 'CodeManageMent',
        component: CodeManageMent,
      },
      {
        path: '/orderManagement',
        name: 'orderManagement',
        component: orderManagement,
      },
      {
        path: '/chatLog',
        name: 'ChatLog',
        component: ChatLog,
      },
      {
        path: '/dataBoard',
        name: 'DataBoard',
        component: DataBoard,
      },
      {
        path: '/onlineService',
        name: 'onlineService',
        component: onlineService,
      },
    ],
  },
]

// routes.befa

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  document.title = '码商支付'
  if (to.path == '/home' && !token) {
    next('/')
  } else {
    next()
  }
})

export default router

<template>
  <div class="chat-log">
    <div class="search-form">
      <el-form :model="searchForm" label-width="100px" inline size="medium">
        <el-form-item label="日期筛选">
          <el-date-picker
            clearable
            style="width: 230px"
            v-model="searchForm.created_time"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单号查询">
          <el-input placeholder="请输入订单号" v-model="searchForm.order_id" clearable></el-input>
        </el-form-item>
        <el-form-item label="客服人员">
          <el-select v-model="searchForm.service_id" placeholder="请选择客服" style="width: 130px" clearable>
            <el-option
              v-for="item in serviceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户账号">
          <el-input placeholder="输入用户名称" v-model="searchForm.user_name" style="width: 130px" clearable></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="order-status">
      <span>订单状态：</span>
      <el-radio-group v-model="searchForm.status" @input="statusChange" :disabled="orderLoading">
        <el-radio :label="null">全部</el-radio>
        <el-radio :label="1">进行中</el-radio>
        <el-radio :label="0">已失效</el-radio>
        <el-radio :label="2">已完成</el-radio>
      </el-radio-group>
    </div>
    <div class="table">
      <el-table
        border
        v-loading="orderLoading"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          align="center"
          prop="sub_time"
          label="日期"
        >
          <template v-slot="{row}">
            <span>{{timeFormat(row.sub_time, 'yyyy-mm-dd')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="order_id"
          label="订单号"
         >
        </el-table-column>

        <el-table-column
          align="center"
          prop="service_id"
          label="客服人员"
        >
          <template v-slot="{row}">
            <template v-if="row.service_id">
              <span>{{returnServiceName(row)}}</span>
            </template>
            <template v-else>
             -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="user_name"
          label="用户账号"
        >
          <template v-slot="{row}">
            <template v-if="row.user_name">
              <span>{{row.user_name}}</span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="order_id"
          label="对话数"
        >
          <template v-slot="{row}">
            {{getTotalData(row, 'talkNum') || '-'}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="订单状态"
        >
          <template v-slot="{row}">
            <template v-if="row.status">
              <span>{{returnOrderStatus(row.status)}}</span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sub_time"
          label="下单时间"
        >
          <template v-slot="{row}">
            <span>{{timeFormat(row.sub_time, 'yyyy-mm-dd hh:MM:ss')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="order_id"
          label="耗时"
        >
          <template v-slot="{row}">
            {{getTotalData(row, 'talkTimeLong') || '-'}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="操作"
        >
          <template v-slot="{row}">
            <el-button @click="checkChat(row)" type="text">查看聊天记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="limit"
          layout="total, prev, pager, next"
          :total="dataCount">
        </el-pagination>
      </div>
    </div>
  <!--  聊天记录  -->
    <el-drawer
      title=" "
      :size="700"
      :visible.sync="chatDrawer"
      @close="chatDrawerClose"
      :with-header="true">
        <div slot="title" class="title">
          <span>与 【{{activeTlak && activeTlak.user_name}}】 的聊天记录</span>
          <span><i class="el-icon el-icon-map-location"></i>未知地区 （{{activeTlak && activeTlak.ip}}）</span>
        </div>
        <div class="chat-list" ref="chatWindow" v-loading="chatHistoryGet">
          <chat-item
            v-for="item in msgList" :key="item._id"
            :msgItem="item"
            :name="activeTlak.user_name"
            :msgUserType="item.toUserType === 1?'self':'others'"
            :showMsgStatus="false"
          />
        </div>
    </el-drawer>
  </div>
</template>

<script>
import ChatItem from "@/components/chat-item.vue";
import {countTalks, get_kefu_list, getOrderList} from "@/api/chatLog";
import timeFormat from "@/utils/timeFormat";
import {getPreviousDate} from "@/utils";
import {getChatHistory} from "@/api/chat";

export default {
  components: {ChatItem},
  data() {
    return {
      searchForm: {
        created_time:[
          getPreviousDate(),
          timeFormat(),
        ],
        open_data_status:null,
      },
      serviceOptions: [],
      tableData: [],
      chatDrawer:false,
      page:1,
      limit:10,
      dataCount:0,
      orderLoading:false,
      //
      activeTlak:{},
      msgList:[],
      chatPage:1,
      chatLimit:20,
      chatCount:0,
      timer:null,
      chatHistoryGet:false,
      chatLoadEnd:false,
      loadChatAfterScrollHeight:0,
      totalData:[],
    }
  },
  mounted() {
    this.getServiceList()
  },
  methods: {
    timeFormat,
    getTotalData(row, key){
      const findData = this.totalData.find(item=>{
        return item.order_id === row.order_id
      })
      if(findData){
        return findData[key] || '-'
      }
      return null
    },
    statusChange(){
      this.search()
    },
    handleSizeChange(val) {
      this.limit = val
      this.page = 1
      this.search()
    },
    handleCurrentChange(val) {
      this.page = val
      this.searchOrder()
    },
    async checkChat(row){
      console.log(row)
      if(!row.order_id){
        this.$message.error("对话ID不存在")
        return
      }
      this.activeTlak = row
      await this.getChatMsgList()
    },
    chatDrawerClose(){
      this.$refs.chatWindow.removeEventListener('scroll', this.loadChat);
      if(this.timer){
        clearTimeout(this.timer)
        this.timer = null
      }
      this.msgList = []
      this.chatPage = 1
      this.chatHistoryGet = false
      this.chatLoadEnd = false
    },
    loadChat(){ // 节流
      if(this.timer) return
      this.timer = setTimeout(()=>{
        let scrolled = this.$refs.chatWindow.scrollTop;
        let scrolledHeight = this.$refs.chatWindow.scrollHeight;
        this.loadChatAfterScrollHeight = scrolledHeight
        if(scrolled <= 100){
          if(this.chatCount <= this.chatLimit) {
            this.$message.info('已加载所有聊天数据')
            return
          }
          if(this.msgList.length >= this.chatCount) {
            this.$message.info('已加载所有聊天数据')
            return
          }
          if(this.chatLoadEnd) {
            this.$message.info('已加载所有聊天数据')
            return
          }
          this.chatPage += 1
          this.getChatMsgList()
        }
        clearTimeout(this.timer)
        this.timer = null
      },500)
    },
    async getChatMsgList(){
      try{
        if(this.chatHistoryGet) return
        this.chatHistoryGet = true
        const res = await getChatHistory({
          order_id: this.activeTlak.order_id,
          page: this.chatPage,
          limit: this.chatLimit
        })
        console.log(res)
        if(res.code === 0){
          const cloneList = JSON.parse(JSON.stringify(res.data))
          if(cloneList.length === 0){
            this.chatLoadEnd = true
          }
          const msgs = cloneList.reverse()
          this.msgList.unshift(...msgs)
          this.chatCount = res.count
          this.chatDrawer = true
          this.$nextTick(function(){
            this.$refs.chatWindow.addEventListener('scroll', this.loadChat);
            if(this.chatPage === 1){
              let scrollElem = this.$refs.chatWindow;
              scrollElem.scrollTo({
                top: scrollElem.scrollHeight,
                behavior: 'smooth'
              });
            }else{
              let scrollElem = this.$refs.chatWindow;
              let scrolledHeight = scrollElem.scrollHeight;
              let boxHeight = scrollElem.offsetHeight
              scrollElem.scrollTo({
                top: scrolledHeight - this.loadChatAfterScrollHeight - boxHeight + 30,
                behavior: 'auto'
              });
            }
          })
          setTimeout(()=>{
            this.chatHistoryGet = false
          },100)
        }else{
          setTimeout(()=>{
            this.chatHistoryGet = false
          },100)
        }
      }catch (e) {
        console.log(e)
        setTimeout(()=>{
          this.chatHistoryGet = false
        },100)
      }
    },
    async getServiceList(){
      try{
        const res = await get_kefu_list()
        this.serviceOptions = res.data.map(item=>{
          return {
            label: item.nickname,
            value: item.service_id
          }
        })
        await this.searchOrder()
      }catch (e) {
        console.log(e)
      }
    },
    search(){
      this.page = 1
      this.searchOrder()
    },
    async searchOrder(){
      if(this.searchForm.created_time.length === 0){
        this.$message.warning("请选择查询日期")
        return
      }
      try{
        if(this.orderLoading) return
        this.orderLoading = true
        const cloneForm = JSON.parse(JSON.stringify(this.searchForm))
        cloneForm.created_time[0] = `${cloneForm.created_time[0]} 00:00:00`
        cloneForm.created_time[1] = `${cloneForm.created_time[1]} 23:59:59`
        cloneForm.created_time = cloneForm.created_time.join(',')
        cloneForm.page = this.page
        cloneForm.limit = this.limit
        // 为空得值不传
        if(cloneForm.open_data_status === null) delete cloneForm.open_data_status
        if(!cloneForm.order_id) delete cloneForm.order_id
        if(!cloneForm.service_id) delete cloneForm.service_id
        if(!cloneForm.user_name) delete cloneForm.user_name
        const res = await getOrderList(cloneForm)
        console.log(res)
        if(res.code === 200){
          this.dataCount = res.data.count
          this.tableData = res.data.list
          const orders = this.tableData.map(item=> item.order_id)
          if(orders && orders.length > 0){
            const totalData = await countTalks({orders: orders.join(',')})
            if(totalData.code === 0) this.totalData = totalData.data
          }
        }
        setTimeout(()=>{
          this.orderLoading = false
        },1000)
      }catch (e) {
        console.log(e)
        setTimeout(()=>{
          this.orderLoading = false
        },1000)
      }
    },
    returnOrderStatus(status){
      const orderStatus = parseInt(status)
      switch (orderStatus){
        case 0:
          return '已失效'
        case 1:
          return '支付中'
        case 2:
          return '支付成功'
        default:
          return '未知'
      }
    },
    returnServiceName(row){
      if(!row.service_id) return '-'
      const service_id = row.service_id
      const service = this.serviceOptions.find(item=>String(item.value) === String(service_id))
      if(!service) return '-'
      return service.label
    }
  }
}
</script>

<style scoped lang="scss">
.chat-log {
  flex: 1;
  overflow-x: hidden;
  background: #F5F6F8;
  box-sizing: border-box;
}

.search-form {
  background: #FFFFFF;
  padding-top: 20px;
}
.order-status{
  font-size: 14px;
  margin: 14px 0;
  color: #666;
}
.chat-list{
  padding: 20px;
  box-sizing: border-box;
  background: #F5F6F8;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-anchor: none;
}
.title{
  display: flex;
  align-items: center;
  span{
    &:nth-child(1){
      flex: 1;
      color: #333;
    }
    &:nth-child(2){
      padding-right: 50px;
      font-size: 14px;
      display: flex;
      align-items: center;
      i{
        font-size: 20px;
        margin-right: 2px;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
</style>
<style>
.chat-log .el-drawer__header{
  margin-bottom: 20px;
}
.chat-log .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  background: #e2e2ea !important;
}
.chat-log .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #2F88FF !important;
}
</style>

<template>
  <div class="login">
    <div class="login-box">
      <img class="logo" src="@/assets/logo.png" alt="" />
      <h4 class="wen-name">人工代充码商服务端</h4>
      <div class="login-form">
        <div class="form-item">
          <span class="label">账号</span>
          <div class="input">
            <img src="@/assets/username.png" alt="" />
            <input type="text" placeholder="请输入账号" v-model="account" />
          </div>
        </div>
        <div class="form-item">
          <span class="label">密码</span>
          <div class="input">
            <img src="@/assets/username.png" alt="" />
            <input
              :type="viewPwdStatus ? 'text' : 'password'"
              placeholder="请输入密码"
              v-model="pwd"
            />
          </div>
          <img
            @click="viewPwdStatus = !viewPwdStatus"
            v-if="viewPwdStatus"
            class="check"
            src="@/assets/check-pwd-active.png"
            alt=""
          />
          <img
            @click="viewPwdStatus = !viewPwdStatus"
            v-else
            class="check"
            src="@/assets/view-password.png"
            alt=""
          />
        </div>
      </div>
      <div class="login-btn" @click="submit">立即登录</div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { login } from '@/api/login'

  export default {
    name: 'Login',
    data() {
      return {
        viewPwdStatus: false,
        account: '',//15148228108  // 18383182202
        pwd: '',//123456
      }
    },
    mounted() {
      this.reloadOrderHandleTime()
    },
    methods: {
      ...mapMutations(['setToken']),
      ...mapMutations('chat', ['reloadOrderHandleTime']),
      async submit() {
        if (!this.account) {
          this.$message.error('账户不能为空')
          return
        }
        if (!this.pwd) {
          this.$message.error('密码不能为空')
          return
        }
        const params = {
          account: this.account,
          password: this.pwd
        }
        const res = await login(params)
        console.log('res is', res)
        if (res && res.code === 200) {
          const token = res.data.token
          const im = res.data.im
          localStorage.setItem('token', token)
          if(im && im.imToken){
            localStorage.setItem('imtoken', im.imToken)
          }
          this.setToken(token)
          this.$router.push('/home')
        } else if (res.msg) {
          //this.$message.error(res.msg)
        } else {
          //this.$message.error('账户或密码错误')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .login {
    height: 100vh;
    background: #d8d8d8 linear-gradient(135deg, #2f88ff 0%, #43ccf8 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-box {
    width: 400px;
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
  .logo {
    width: 68px;
    margin-bottom: 30px;
  }
  .wen-name {
    font-size: 27px;
    color: #121c32;
    margin-bottom: 40px;
  }
  .login-form {
    width: 70%;
  }
  .form-item {
    height: 40px;
    border: 1px solid #cecfd4;
    border-radius: 10px;
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 20px;
    .label {
      position: absolute;
      top: -10px;
      left: 26px;
      display: block;
      padding: 0 12px;
      background: #fff;
      color: #4f4f55;
      font-size: 12px;
    }
    .input {
      flex: 1;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      input {
        flex: 1;
        border: none;
        user-select: none;
        outline: none;
        padding: 5px 14px;
      }
    }
    .check {
      width: 16px;
      height: 16px;
    }
  }

  .login-btn {
    margin-top: 10px;
    width: 50%;
    height: 40px;
    background: #d8d8d8 linear-gradient(135deg, #2f88ff 0%, #43ccf8 100%);
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-radius: 18px;
    margin-bottom: 50px;
    &:hover {
      cursor: pointer;
    }
  }
</style>

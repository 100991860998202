<template>
  <div class="clear-history-modal">
    <el-dialog
      title="确定清空本地聊天记录？"
      :visible="dialogVisible"
      width="480px"
      top="30vh"
      center
      :before-close="close">
      <span class="tips">此操作将不可恢复，请谨慎处理</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" style="width: 100px;
background: #D8D8D8 linear-gradient(135deg, #2F88FF 0%, #43CCF8 100%); border-radius: 5px; border: none">确 定</el-button>
        <el-button @click="close" style="width: 100px">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import localforage from "localforage";

export default {
  name: "clearLocaHistoryModal",
  data(){
    return {
      dialogVisible:true
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    confirm(){
      localforage.clear()
      this.$message({
        type: 'success',
        message: '清除本地历史消息成功!'
      })
      this.close()
    }
  }
}
</script>

<style scoped>
.tips{
  display: block;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
</style>
<style>
.clear-history-modal .el-dialog__title{
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;
}
.clear-history-modal .el-dialog__headerbtn .el-dialog__close{
  color: #333;
  font-size: 20px;
}
</style>

<template>
  <el-dialog
    title="收款码"
    :visible="true"
    width="770px"
    top="5vh"
    center
    :before-close="close">
    <div class="code-list">
      <span class="title">常用收款码</span>
      <div class="list">
        <div class="item"
             :class="{active: commonActive===index}"
             v-for="(item,index) of commonList"
             :key="item.code_id"
             @click="checkItem(index)"
        >
          <div class="img">
            <img :src="item.url" alt="">
          </div>
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="code-list"
         style="margin-bottom: 0"
         v-if="talkItem && talkItem.ip && talkItem.area"
    >
      <span class="title" v-if="cityList.length">用户当前所在城市收款码（{{talkItem.area}}）</span>
      <div class="list">
        <div class="item"
             :class="{active: cityCodeActive===index}"
             v-for="(item,index) of cityList"
             :key="item.code_id"
             @click="checkCityItem(index)"
        >
          <div class="img">
            <img :src="item.url" alt="">
          </div>
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirm" style="width: 240px;
background: #D8D8D8 linear-gradient(135deg, #2F88FF 0%, #43CCF8 100%); border-radius: 20px">发 送</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {codeList} from "@/api/code"

export default {
  name: "collectionCode",
  props:{
    talkItem:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      commonList:[],
      cityList:[],
      commonActive:null,
      cityCodeActive:null,
    }
  },
  mounted() {
    this.getList()
    this.cityCodeList()
  },
  methods: {
    returnAddressText(item){
      if(!item) return '未获取到地区信息'
      try {
        const info = JSON.parse(item)
        return info[info.length-1].name
      }catch (e) {
        return '未获取到地区信息'
      }
    },
    close() {
      this.$emit('close')
    },
    confirm() {
      if(this.commonActive == null && this.cityCodeActive == null){
        this.$message({
          type: 'warning',
          message: '请选择一个收款码!'
        });
        return
      }
      if(this.commonActive != null){
        this.$emit('sendCode',this.commonList[this.commonActive])
      }
      if(this.cityCodeActive != null){
        this.$emit('sendCode',this.cityList[this.cityCodeActive])
      }
    },
    async getList(){
      const {data} = await codeList({common:1})
      if(data.list) this.commonList = data.list
    },
    async cityCodeList(){
      const {data} = await codeList({talk_id: this.talkItem.order_id})
      if(data.list) this.cityList = data.list
    },
    checkItem(index){
      this.cityCodeActive = null
      this.commonActive = index
    },
    checkCityItem(index){
      this.commonActive = null
      this.cityCodeActive = index
    }
  }
}
</script>

<style lang="scss" scoped>
.code-list {
  margin-bottom: 20px;

  .title {
    font-size: 16px;
    color: #333333;
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 14px;
      margin-bottom: 20px;
      &.active {
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          width: 130px;
          height: 130px;
          background: #D8D8D8 linear-gradient(135deg, #2F88FF 0%, #43CCF8 100%);
        }
      }
      .img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 130px;
        border-radius: 6px;
      }
      img {
        width: 120px;
        height: 120px;
      }

      span {
        font-size: 14px;
        color: #999999;
        padding-top: 16px;
      }
    }
  }
}
</style>

import request from '@/utils/axios'
import {IM_HOST} from "@/config";

// 获取话题列表
export function getChatList(data) {
  return request({
    baseURL: IM_HOST,
    url: '/v1/user/getTalkList',
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    method: 'get',
    params: data,
  })
}
// 获取聊天记录
export function getChatHistory(params) {
  return request({
    baseURL: IM_HOST,
    url: '/v1/message/getUserAllMsg',
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    method: 'get',
    params
  })
}

// 完成订单 apiorder_id
export function confirmOrder(data) {
  return request({
    url: '/Artificialorder/supplementary_order',
    method: 'post',
    data,
  })
}
// 完成订单 apiorder_id
export function getOrderInfo(orderId) {
  return request({
    url: '/serviceapi/getOrderInfo/' + orderId,
    method: 'get',
  })
}

// 图片上传
export function uploadFile(data) {
  return request({
    baseURL: IM_HOST,
    url: '/v1/file/uploadFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    data,
  })
}

// 更新话题列表
export function updateTalkStatus(data) {
  return request({
    baseURL: IM_HOST,
    url: '/v1/user/updateTalkStatus',
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    method: 'post',
    data
  })
}

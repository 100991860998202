import request from '../utils/axios.js'
import {IM_HOST} from "@/config";

// 收款码列表
export function codeList(params) {
  return request({
    url: '/Paycode/codeList',
    method: 'get',
    params,
  })
}

// 添加收款码
export function addCode(data) {
  return request({
    url: '/Paycode/addCode',
    method: 'post',
    data,
  })
}

// 添加收款码
export function codeDelete(data) {
  return request({
    url: '/Paycode/codeDelete',
    method: 'post',
    data,
  })
}

// 收款码修改
export function updateCode(data) {
  return request({
    url: '/Paycode/updateCode',
    method: 'post',
    data,
  })
}

// 获取订单信息
export function getOrderInfo(orderid) {
  return request({
    url: `/Artificialorder/getOrderInfo/${orderid}`,
    method: 'get',
  })
}

// 获取订单列表
export function get_pay_service_order_list(data) {
  return request({
    url: `/Artificialorder/get_pay_service_order_list`,
    method: 'post',
    data
  })
}
// 补单
export function supplementary_order(data) {
  return request({
    url: `/Artificialorder/supplementary_order`,
    method: 'post',
    data
  })
}
// 获取在线客服列表
export function getonline(params) {
  return request({
    baseURL: IM_HOST,
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    url: `/v1/user/getServiceList`,
    method: 'get',
    params
  })
}
// 客服转接话题至其他客服
export function transformkefu(data) {
  return request({
    url: `/serviceapi/transformkefu`,
    method: 'post',
    data
  })
}
// 强制客服离线
export function offline(data) {
  return request({
    baseURL: IM_HOST,
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    url: `/v1/user/offLine`,
    method: 'post',
    data
  })
}

import axios from 'axios'
import { BASE_URL } from '@/config/index.js'
import { Message } from 'element-ui'
import Router from "@/router"

const baseURL = BASE_URL

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL,
  timeout: 300000, // 超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(requestConf, (error) => {
  return Promise.reject(error)
})

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => handleData(response),
  (error) => {
    Message({ message: '网络故障请检查网络', type: 'error', duration: 5000 })
    const { response } = error
    if (response === undefined) {
      return {}
    } else return handleData(response)
  }
)

function requestConf(config) {
  // 请求头 token 处理
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

// config, data, status, statusText
function handleData(res) {
  const { data, status } = res
  //console.log('data is', data)
  if (status !== 200) {
    Message({
      message: `出错了：${res.statusText}`,
      type: 'error',
      duration: 5000,
    })
    return res
  }else{
    if (data && data.code !== 200 && data.code !== 0) {
      if(data.code === 500){
        Message({
          message: `ERROR:：${data.msg}`,
          type: 'error',
          duration: 5000,
        })
      }else{
        if(data.code === 101 || data.code === 102){
          Message({
            message: data.msg||`登录已过期，请重新登录！`,
            type: 'error',
            duration: 5000,
          })
          Router.push("/")
          return res
        }
        Message({
          message: `错误：${data.msg}`,
          type: 'error',
          duration: 5000,
        })
      }
    }
    return data
  }
}

export default instance

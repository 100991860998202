<template>
  <div class="code-management">
      <div class="form">
        <el-radio-group v-model="codeType" style="padding-right: 20px" @change="codeChange">
          <el-radio label="1">常用收款码</el-radio>
          <el-radio label="2">城市收款码</el-radio>
        </el-radio-group>
        <el-select v-model="province" clearable @change="addressChange" placeholder="请选择省份" style="width:180px; margin-right: 10px">
          <el-option
            v-for="item in addressJson"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          >
          </el-option>
        </el-select>
        <el-select v-if="cityList.length"  clearable multiple collapse-tags v-model="city" placeholder="请选择城市" style="width:180px">
          <el-option key="all" label="全部" value="all"></el-option>
          <el-option
            v-for="item in cityList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
            :disabled="city.find(item=>item==='all')"
          >
          </el-option>
        </el-select>
        <div class="btn">
            <div class="add-btn" @click="addFoemShow=true">添加收款码</div>
        </div>
      </div>
      <div class="codes">
        <div class="tools">
          <el-checkbox v-model="checkAllModel" style="margin-right: 20px" @change="checkAll">全选</el-checkbox>
          <el-button @click="delCode">删除</el-button>
          <el-button @click="editCode">修改</el-button>
        </div>
        <div class="code-list">
          <div class="code-item" v-for="(item,index) of codeList" :key="item.code_id" @click="checkItem(index)">
            <img class="qr-code" :src="item.url" alt="">
            <span class="title">{{item.name}}</span>
            <img v-if="item.checked" class="checkbox" src="@/assets/checkbox-active.png" alt="">
            <img v-else class="checkbox" src="@/assets/checkbox.png" alt="">
          </div>
        </div>
      </div>
    <!--    -->
    <addcode v-if="addFoemShow" @close="addCodeClose" :editData="editData"></addcode>
  </div>
</template>

<script>
import Addcode from "@/components/addcode.vue";
import {codeList,codeDelete} from "@/api/code"
import addressJson from "@/assets/address.json"

export default {
  name: "codeManagement",
  components: {Addcode},
  data(){
    return {
      codeType:'1',
      province:'',
      city:[],
      checkAllModel:false,
      addFoemShow:false,
      codeList:[],
      editData:{},
      addressJson,
      cityList:[]
    }
  },
  mounted() {
    this.getcodeList()
  },
  watch:{
    city(){
      this.getcodeList()
    },
    province(val){
      if(!val){
        this.cityList = []
      }
    }
  },
  methods:{
    addressChange(e){
      this.city = []
      const cityList = this.addressJson.find(item=>item.code === e)
      if(cityList){
        this.cityList = cityList.children
      }
    },
    async getcodeList(){
      try {
        const params = {}
        if(this.codeType === '1'){
          params.common = 1
        }
        if(this.codeType === '2'){
          params.city = 1
          if(this.city.length && this.province) {
            const findAll = this.city.find(item=>item==='all')
            if(findAll === undefined){
              params.position = this.city.join(',')
            }else{
              const citys = this.addressJson.find(item=>item.code === this.province)
              params.position = citys.children.map(item=>item.code).join(',')
            }
          }
        }
        const {data} = await codeList(params)
        this.codeList = data.list
      }catch (e) {
        console.log(e)
      }
    },
    codeChange(){
      this.getcodeList()
    },
    checkItem(index){
      const checked = this.codeList[index].checked?false:true
      this.$set(this.codeList[index],'checked',checked)
    },
    checkAll(e){
      if(e){
        this.codeList.forEach((item,index)=>{
          this.$set(this.codeList[index],'checked',true)
        })
      }else{
        this.codeList.forEach((item,index)=>{
          this.$set(this.codeList[index],'checked',false)
        })
      }
    },
    delCode(){
      const checkedItems = this.codeList.filter(item=>item.checked)
      if(checkedItems.length){
        this.$confirm('此操作将永久删除收款码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const params = {
            code_id: checkedItems.map(item=>item.code_id).join(',')
          }
          codeDelete(params).then(()=>{
            this.getcodeList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }).catch(()=>{
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          })
        })
      }else{
        this.$message({
          message: '请至少选中一个收款码',
          type: 'warning'
        });
      }
    },
    editCode(){
      const checkedItems = this.codeList.filter(item=>item.checked)
      if(checkedItems.length){
        if(checkedItems.length > 1){
          this.$message({
            message: '一次只能编辑一个收款码',
            type: 'warning'
          });
          return
        }
        this.editData = checkedItems[0]
        this.addFoemShow = true
      }else{
        this.$message({
          message: '请选中一个收款码',
          type: 'warning'
        });
      }
    },
    addCodeClose(){
      this.addFoemShow=false
      this.editData = {}
      this.getcodeList()
    }
  },
  sockets: {
    offLine(value) {
      console.log('offLine', value)
      this.$socket.emit('close')
      this.$socket.disconnect()
      this.$router.push('/')
    },
    msg(value) { // 消息
      console.log('msg 新消息：', value)
      if (value.code === 0 && value.data.formUid) {
        this.handlePlay()
        // 缓存消息
        this.setImMsgList(value.data)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.code-management{

}
.form{
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 30px;
  .btn{
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
.add-btn {
  width: 136px;
  height: 40px;
  background: #f5f6f8 linear-gradient(135deg, #2f88ff 0%, #43ccf8 100%);
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  margin: 18px 0;
  text-align: center;
  line-height: 40px;
  &:hover {
    cursor: pointer;
  }
}
.codes{
  background: #fff;
  margin-top: 14px;
  padding: 30px;
}
.code-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.code-item{
  flex: 0 0 140px;
  background: #F5F6F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 16px;
  cursor: pointer;
  .qr-code{
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }
  .title{
    font-size: 14px;
    color: #333333;
    padding-bottom: 16px;
    text-align: center;
  }
  .checkbox{
    width: 22px;
    height: 22px;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div class="chat-page" @dragenter="dropShow=true">
    <div class="chat-list">
      <h6 class="title">消息列表</h6>
      <div class="user-list">
        <!--  -->
        <el-empty
          v-if="!getTalkList.length"
          description="暂无订单"
          :image="img"
        ></el-empty>
        <div
          v-for="(item,index) of getTalkList"
          :key="index"
          :class="['user-item', currentTalkId == item.order_id ? 'active' : '']"
          @click="handleClick(item.order_id, item)"
        >
          <div class="header-icon">
            <el-avatar class="head-img" :size="40" :src="item.headImgUrl">
              <img :src="require('../assets/avatar.png')" alt=""/>
            </el-avatar>
            <i :class="{unline: !item.online}"></i>
            <el-badge
              v-if="getNoreadKeys(item.order_id)"
              :value="getNoreadKeys(item.order_id)"
              class="icon"
            ></el-badge>
          </div>
          <!--  -->
          <div class="info">
            <span class="name">{{ item.talk_title || '用户名为空' }}</span>
            <span class="order-id">订单号 {{ item.order_id }}</span>
            <span class="time">{{ formatTime(item.add_time, false) }} {{ item.area || '未获取到地区信息' }}</span>
            <!-- <span>{{ item.no_read }}</span> -->
          </div>
        </div>
      </div>
      <div class="pagination" v-if="(type ===0 || type ===2) && talkListCount">
        <el-button-group>
          <el-button @click="changePage(1)" type="primary" :disabled="talkListCount < 20 || page === 1" size="mini" icon="el-icon-arrow-left">上一页</el-button>
          <el-button @click="changePage(2)" type="primary" size="mini" :disabled="page >= Math.ceil(talkListCount / 20)">下一页<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group>
      </div>
    </div>
    <div
      class="chat"
      v-if="talkInfo.order_id && getTalkList.length"
      v-loading="loading && !talkInfo"
      element-loading-text="数据加载中..."
      @dragover.prevent @drop="dropHandler"
    >
      <div class="dropzone" @dragleave="dropShow=false" v-if="dropShow">将图片拖拽到此处发送图片</div>
      <div class="header" v-if="currentTalkId">
        与 {{ talkItem.username || '暂无用户' }} 用户交流中
        <div class="location-info">
          <img src="@/assets/location-icon.png" alt="">
          <span>{{ talkInfo.area || '未获取到地区信息' }} （{{ talkInfo.ip }}）</span>
          <el-popover
            placement="bottom"
            title="更多信息"
            width="200"
            trigger="click"
          >
            <el-button type="primary" size="mini" slot="reference" style="margin: 0">更多信息</el-button>
            <slot name="reference">
              <div style="display: flex; flex-direction: column">
                <span class="reference-item">ip: {{ talkInfo.ip || '未知'}}</span>
                <span class="reference-item">地区信息: {{ talkInfo.area || '未知'}}</span>
                <span class="reference-item">设备品牌: {{ talkInfo.brand || '未知'}}</span>
                <span class="reference-item">设备型号: {{ talkInfo.model || '未知'}}</span>
                <span class="reference-item">APP版本号: {{ talkInfo.appVersion || '未知'}}</span>
              </div>
            </slot>
          </el-popover>
        </div>
      </div>
      <div class="chat-box" ref="chatbox">
        <div v-for="item of getMsgs" :key="item.msgKey">
          <chat-item
            :msgItem="item"
            :talkInfo="talkInfo"
            :name="talkItem && talkItem.username"
            :msgUserType="String(item.formUid) === String(userInfo.service_id) || !item.formUid?'self':'others'"
          />
          <selectPayChatItem
            v-if="item.msgType === 1 && item.extend && item.extend.newOrder"
            :msgItem="item"
            :name="talkItem && talkItem.username"
            msgUserType="self"
          />
        </div>
      </div>
      <div class="tools">
        <div class="left">
          <i class="el-icon-picture-outline-round" v-if="false"></i>
          <i class="el-icon-picture" @click="handleUploadImg"></i>
          <img src="@/assets/send-qrcode-icon.png" alt="" @click="getCollectionCode">
          <span v-if="talkInfo.talkStatus === 1" @click="handleOpen">
            订单处理
          </span>
          <span v-if="talkInfo.talkStatus === 1" @click="transferService" style="margin-left: 20px">
            转接客服
          </span>
        </div>
        <div class="right" @click="copyData">
          订单号 {{ currentTalkId }}
          <el-button type="primary" size="small" style="margin-left: 20px; margin-bottom: -3px"
                     @click.stop="orderdSearchDialogVisible=true">
            订单查询
          </el-button>
        </div>
      </div>
      <div class="msg-input">
        <textarea
          placeholder="请输入..."
          v-model="msgContent"
          @keyup.enter="handleEnter"
        ></textarea>
        <span class="send-btn" @click="handleSend">发送</span>
      </div>
    </div>
    <!--    处理订单    -->
    <el-dialog
      title="用户申请的充值订单"
      v-if="dialogVisible"
      :visible="true"
      width="30%"
      center
      @close="dialogVisible = false"
    >
      <div class="dialog-content">
        <p>订单号：{{ orderId }}</p>
        <p>金额：{{ talkInfo.order_money }}元</p>
      </div>
      <span class="tips">如果用户已完成支付</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handelUpdateOrder">
          确认用户已支付
        </el-button>
      </span>
    </el-dialog>
    <input
      ref="upload"
      style="display: none"
      type="file"
      name="fileUpload"
      @change="onFileChange"
    />
    <!--    -->
    <collection-code v-if="collectionCodeShow" @close="collectionCodeShow=false" @sendCode="sendCode"
                     :talkItem="talkInfo"></collection-code>
    <!--  订单查询  -->
    <el-dialog
      title="订单查询"
      :visible.sync="orderdSearchDialogVisible"
      width="50%"
    >
      <div v-if="orderdSearchDialogVisible">
        <order-search/>
      </div>
    </el-dialog>
    <!--  在线客服列表  -->
    <el-dialog
      title="转接客服"
      :visible.sync="transferServiceVisible"
      width="600px"
    >
      <div>
        <el-button
          v-for="item of onlineServiceList"
          :key="item.uid"
          size="medium"
          :type="activeOnlineService === item.uid?'primary':''"
          round
          @click="selectService(item)"
        >
          <div style="display: flex; align-items: center">
            <el-avatar :size="20" :src="item.headImgUrl" style="margin-right: 4px">
              <img src="@/assets/logo.png"/>
            </el-avatar>
            {{ item.username }}
          </div>
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transferServiceVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmTransferService">确 定</el-button>
      </span>
    </el-dialog>
    <!--    -->
    <audio
      ref="audio"
      style="display: none"
      controls
      src="../assets/msg.mp3"
    >
      当前浏览器不支持audio标签
    </audio>
  </div>
</template>

<script>
import ChatItem from '@/components/chat-item.vue'
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex'
import {formatTime, removeSpaces} from '@/utils/index.js'
import {confirmOrder, getOrderInfo, updateTalkStatus, uploadFile} from '@/api/chat.js'
import {v4 as uuidv4} from "uuid"
import {changeUserSate} from '@/api/login.js'
import img from '@/assets/empty.png'
import throttle from 'lodash.throttle'
import {Loading} from 'element-ui';
import CollectionCode from "@/components/collectionCode.vue";
import {compressImageTo200KB} from "@/utils/imgZip"
import localforage from "localforage";
import orderSearch from "@/components/orderSearch.vue";
import {getonline} from "@/api/code";
import selectPayChatItem from "@/components/selectPayChatItem.vue"

export default {
  name: 'ChatPage',
  components: {CollectionCode, ChatItem, orderSearch, selectPayChatItem},
  data() {
    return {
      dialogVisible: false,
      money: 0,
      loading: false,
      msgContent: '',
      user_name: '',
      img: img,
      isOnline: sessionStorage.getItem('isOnline') || false,
      fn: null,
      msgList: [],
      UnreadMsgNums: [],
      collectionCodeShow: false,
      talkItem: {},
      confirmOrderStatus: false,
      orderdSearchDialogVisible: false,
      transferServiceVisible: false,
      onlineServiceList: [],
      activeOnlineService: '',
      file: null,
      getTime: null,
      dropShow: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState('chat', ['type', 'page', 'talkListCount', 'list', 'currentTalkId', 'workStatus', 'loading1', 'msgNum', 'lastChatId', 'readIDS', 'chatNoReadList', 'imMsgList', 'noreadMsgKeys', 'noReadList']),
    ...mapGetters('chat', ['getTalkList', 'getNoredNum', 'getMsgs']),
    orderId() {
      if (!this.list && !this.list.length) return 0
      const raw = this.list.find(
        (item) => item.order_id === this.currentTalkId
      )
      if (raw) {
        return raw.order_id
      }
      return 0
    },
    talkInfo() {
      if (!this.list && !this.list.length) return {}
      const raw = this.list.find(
        (item) => item.order_id === this.currentTalkId
      )
      return raw || {}
    },
    online() {
      if (this.userInfo) {
        return this.userInfo.online === 1
      } else {
        return false
      }
    },
  },
  created() {
    this.fn = throttle(this.handleSend, 1000)
  },
  watch: {
    msgNum: {
      handler: function () {
        if (this.currentTalkId) {
          this.handleClick(this.currentTalkId)
        }
        setTimeout(() => {
          this.reloadUnreadMsgNum()
        }, 1000)
      },
      immediate: true
    },
    list() {
      this.reloadUnreadMsgNum()
    },
    getMsgs: {
      handler: function () {
        setTimeout(() => {
          if (this.talkInfo.username) {
            const scrollTop = this.$refs.chatbox.scrollHeight
            this.$refs.chatbox.scrollTop = scrollTop
          }
        }, 300)
      },
      deep: true
    },
    lastChatId: {
      handler: function () {
        setTimeout(() => {
          this.handleClick(this.currentTalkId, this.talkItem)
        }, 800)
      },
      deep: true
    }
  },
  methods: {
    formatTime,
    ...mapMutations('chat', [
      'setTalkId',
      'pushMsg',
      'updateNum',
      'pushClentMsg',
      'delCurrentChat',
      'reloadOrderHandleTime',
      'SOCKET_STATUS',
      'setImMsgList',
      'setReadKeys',
      'clearUserNoRead',
      'setOnlineList',
      'clearNoReadNum',
      'setNoReadList',
      'orderHandleTime',
      'reloadOrderHandleTime',
      'setWorkStatus',
      'setBadgeShow',
      'setPage'
    ]),
    ...mapActions('chat', ['getChatHistory', 'updateTalkList', 'getChatLists']),
    showDrop() {
      this.dropShow = true
    },
    changePage(type) {
      if (type === 1) {
        if (this.page === 1) return
        this.setPage(this.page - 1)
      } else {
        if(this.page >= Math.ceil(this.talkListCount / 20)) return
        this.setPage(this.page + 1)
      }
      this.getChatLists()
    },
    async dropHandler(e) {
      e.preventDefault()
      e.stopPropagation()
      const files = e.dataTransfer.files
      if (files.length) {
        if (files.length > 1) {
          this.$message.warning("一次只能拖拽发送一张图片")
          return
        }
        try {
          const file = Array.from(files)[0]
          const load = Loading.service({
            body: true,
            fullscreen: false,
            text: "上传中...",
            background: "rgba(0,0,0,0)"
          });
          this.file = await compressImageTo200KB(file)
          let formData = new FormData()
          formData.append('file', this.file)
          console.log(formData)
          const res = await uploadFile(formData)
          console.log('图片上传结果', res)
          load.close()
          if (res && res.code === 0) {
            this.sendMethods(2, res.data)
          }
          files.value = ''
          this.loading = false
          this.dropShow = false
          this.$refs.upload.value = ''
        } catch (e) {
          this.dropShow = false
        }
      }
    },
    getNoreadKeys(order_id) {
      const keys = this.noReadList[order_id] || []
      if (!keys || !keys.length) return 0
      return keys.length
    },
    async confirmTransferService() {
      if (!this.activeOnlineService) {
        this.$message.warning("请选择转接客服")
        return
      }
      try {
        this.$socket.emit('switchServiceByKefu', {
          order_id: this.talkInfo.order_id,
          toServiceId: this.activeOnlineService,
          key: this.talkInfo.key
        })
      } catch (e) {
        console.log(e)
      }
    },
    selectService(item) {
      if (this.activeOnlineService === item.uid) {
        this.activeOnlineService = ''
        return
      }
      this.activeOnlineService = item.uid
    },
    // 转接客服
    transferService() {
      this.getOnlineService()
    },
    async getOnlineService() {
      try {
        const res = await getonline({onLine: 1, isWork: 1})
        const serviceList = res.data.filter(item => String(item.uid) !== String(this.userInfo.service_id))
        if (serviceList.length) {
          this.onlineServiceList = serviceList
          this.transferServiceVisible = true
        } else {
          this.$message.warning("暂无其他在线客服")
        }
      } catch (e) {
        this.$message.warning("暂无其他在线客服")
      }
    },
    returnAddressText(item) {
      if (!item) return '未获取到地区信息'
      try {
        const info = JSON.parse(item)
        if (info && info.area) return info.area
        if (info && info.country) return `${info.country} ${info.province} ${info.county}`
        return '未获取到地区信息'
      } catch (e) {
        return '未获取到地区信息'
      }
    },
    async sendCode(codeItem) {
      console.log(codeItem)
      this.sendMethods(12, codeItem.url)
      const msgContent = `${codeItem.code_type === 'wechat' ? '微信支付' : '支付宝支付'}  如果您在充值过程中遇到失败及时联系客服处理，并提供收款截图`
      this.sendMethods(1, msgContent)
      this.collectionCodeShow = false
    },
    getCollectionCode() {
      this.collectionCodeShow = true
    },
    async handleClick(order_id, talkItem) {
      console.log(order_id, talkItem)
      this.setTalkId(order_id)
      this.talkItem = talkItem
      // 设置已读
      const noreadKeys = this.noReadList[order_id]
      if (noreadKeys && noreadKeys.length) {
        this.$socket.emit('setRead', {
          uid: order_id,
          msgKeyList: noreadKeys
        })
        this.clearUserNoRead({order_id})
      }
    },
    timer(time) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
    // 刷新未读消息数量列表
    async reloadUnreadMsgNum() {
      this.UnreadMsgNums = []
      for (let i = 0; i < this.getTalkList.length; i++) {
        const chatUnread = await localforage.getItem(`chatUnread-${this.getTalkList[i].talk_id}`)
        if (chatUnread) {
          this.UnreadMsgNums.push({
            talk_id: this.getTalkList[i].talk_id,
            num: chatUnread.num
          })
        }
      }
    },
    // 返回未读消息数量
    talkUnreadNum(talk_id) {
      const find = this.UnreadMsgNums.find(item => item.talk_id === talk_id)
      if (find) {
        return find.num
      }
      return 0
    },
    async clearUnreadMsgNum(talk_id) {
      await localforage.removeItem(`chatUnread-${talk_id}`)
    },
    // 完成订单
    async handelUpdateOrder() {
      try {
        this.reloadOrderHandleTime()
        if (this.confirmOrderStatus) return
        this.confirmOrderStatus = true
        const params = {
          order_id: this.currentTalkId,
        }
        const res = await confirmOrder(params)
        if (res && res.code === 200) {
          const updateStatus = await updateTalkStatus({
            order_id: this.currentTalkId,
            talkStatus: 2,
          })
          if (updateStatus && updateStatus.code === 0) {
            this.$message.success(res.msg)
            this.sendMethods(1, `订单号${this.currentTalkId} ${this.talkInfo.order_money}元 已支付完成`, {"overOrder": 2})
            setTimeout(() => {
              this.confirmOrderStatus = false
              this.getChatLists()
            }, 5000)
            this.dialogVisible = false
          }
        }else{
          this.$message.warning("订单通知失败，请重试")
        }
      } catch (error) {
        console.error(error)
        this.confirmOrderStatus = false
      }
    },
    // 获取订单详情
    async getOrderInfo() {
      try {
        const orderId = this.currentTalkId
        const res = await getOrderInfo(orderId)
        console.log('订单详情', res)
        if (res && res.code === 200) {
          // TODO: 渲染订单详情
          this.money = res.data.order_money
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 查看订单面板
    async handleOpen() {
      //await this.getOrderInfo()
      this.dialogVisible = true
    },
    async handleSend() {
      if (!this.msgContent) return
      const trimStr = removeSpaces(this.msgContent)
      if (trimStr === '') return
      this.sendMethods(1, this.msgContent)
      this.msgContent = ''
    },
    sendMethods(msgType = 1, msgContent = '', msgExtend = null) {
      if (!this.currentTalkId) {
        this.$message.warning('无订单信息')
        return
      }
      const msgKey = uuidv4()
      this.$socket.emit('msg', {
        msgType: msgType, // 消息类型-必传
        toUser: this.talkItem.order_id, // 发送给谁 - 必传
        msg: msgContent,  //消息内容-必传，图片消息此处为图片上传后返回的地址
        extend: msgExtend,
        msgKey
      })
      const createStoreMsg = {
        extend: msgExtend,
        formUid: this.userInfo.service_id,
        formName: this.userInfo.nickname,
        formUserPhoto: '',
        msg: msgContent,
        msgTime: Date.now(),
        msgType: msgType,
        read: 0,
        toID: this.talkInfo.order_id,
        toName: this.talkInfo.username,
        toUserPhoto: '',
        toUserType: 1,
        order_id: this.currentTalkId,
        received: 0,
        msgKey
      }
      this.setImMsgList(createStoreMsg)
      //this.setLastMsg({msg: createStoreMsg, userType: 1})
      this.setTalkId(this.currentTalkId)
      this.reloadOrderHandleTime() // 清除业务处理监听定时器
    },
    handleEnter() {
      this.fn()
    },
    async onFileChange(e) {
      console.log(e)
      const load = Loading.service({
        body: true,
        fullscreen: false,
        text: "上传中...",
        background: "rgba(0,0,0,0)"
      });
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.file = await compressImageTo200KB(files[0])
      let formData = new FormData()
      formData.append('file', this.file)
      console.log(formData)
      const res = await uploadFile(formData)
      console.log('图片上传结果', res)
      load.close()
      if (res && res.code === 0) {
        this.sendMethods(2, res.data)
      }
      files.value = ''
      this.loading = false
      this.$refs.upload.value = ''
    },
    handleUploadImg() {
      if (this.$refs.upload) {
        this.$refs.upload.click()
      }
    },
    // 修改客服状态
    async changeState() {
      try {
        this.loading = true
        const params = {
          isWork: this.online ? 2 : 1,
        }
        if (this.online) {
          this.loading = true
        }
        const res = await changeUserSate(params)
        sessionStorage.setItem('isOnline', !this.online)
        console.log('res is', res)
        this.loading = false
        this.$message.success(res.msg)
        await this.getUserInfo()
        if (!this.online) {
          this.loading = true
        }
      } catch (error) {
        console.error(error)
      }
    },
    // copy订单号
    copyData() {
      //如果不需要回调：
      // this.$copyText(this.orderId)
      //如果需要回调：
      this.$copyText(this.currentTalkId).then(
        (e) => {
          this.$message.success('已复制到粘贴板')
          console.log(e)
        },
        function (e) {
          this.$message.error('复制出错')
          console.log(e)
        }
      )
    },
    // 播放消息提示音
    handlePlay() {
      this.$refs.audio.play()
    },
  },
  sockets: {
    connect() {
      console.log('connect', 'socketio 开始建立连接')
    },
    //服务端向客户端发送connection事件
    connection: function (value) { // 建立授权连接
      console.log('socketio 连接返回：', value)
      if (value.code === -1) {
        this.$message.error('socketio err:' + value.msg || 'ws连接失败')
      }
      if (value.code === 0) {
        this.SOCKET_STATUS(true)
        this.getChatLists()
      }
    },
    activeClose: function (value) { // 连接关闭
      console.log('activeClose', value)
      this.SOCKET_STATUS(false)
    },
    disconnect: async function (value) { // 连接关闭
      console.log('disconnect', value)
      this.SOCKET_STATUS(false)
      this.setWorkStatus(2)
    },
    msg(value) { // 消息
      console.log('msg 新消息：', value)
      if (value.code === 0 && value.data.formUid) {
        this.handlePlay()
        const checkOrderid = this.imMsgList[value.data.order_id]
        if (!checkOrderid) { // 新订单
          this.getChatLists()
          this.orderHandleTime()
        }
        if (value.data.extend && value.data.extend.overOrder === 1) {
          if (this.getTime) {
            clearTimeout(this.getTime)
            this.getTime = null
          }
          this.getTime = setTimeout(() => {
            this.getChatLists()
          }, 700)
        }
        if (value.data.msgKey) {
          // 回复已收到
          this.$socket.emit('receivedMsg', {
            msgKey: value.data.msgKey
          })
          // 判断窗口，提交已读，和缓存未读
          if (value.data.order_id === this.currentTalkId) {
            this.$socket.emit('setRead', {
              uid: value.data.formUid,
              msgKeyList: [value.data.msgKey]
            })
          } else {
            this.setNoReadList(value.data)
          }
        }
        // 缓存消息
        this.setImMsgList(value.data)
      }
    },
    msgRead(value) {
      console.log('msgRead', value)
      if (value.order_id) {
        this.setReadKeys(value)
      }
    },
    presenceNotice(value) {
      console.log('presenceNotice', value)
      this.setOnlineList(value)
    },
    offLine(value) {
      console.log('offLine', value)
      this.$socket.emit('close')
      this.$socket.disconnect()
      this.$router.push('/')
    },
    switchServiceByKefu(value) {
      if (value.code === 0) {
        this.reloadOrderHandleTime()
        this.$message.success(value.msg || "转接客服成功")
        this.transferServiceVisible = false
        setTimeout(() => {
          this.getChatLists()
        }, 700)
      } else {
        this.$message.error(value.msg)
      }
    },
    switchToService(value) {
      if (value.code === 0) {
        this.$message.success(value.msg)
        setTimeout(() => {
          this.getChatLists()
        }, 500)
      } else {
        this.$message.error(value.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-page {
  height: 100%;
  max-width: 95%;
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
}

.chat-list {
  flex: 0 0 300px;
  border-right: 1px solid #eeeff0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .title {
    border-bottom: 1px solid #eeeff0;
    padding: 14px 20px;
    color: #333333;
    font-size: 14px;
  }

  .user-list {
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
}

.user-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eeeff0;
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    top: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: #f5f6f8;
    border-bottom: none;
  }

  .header-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;

    i {
      position: absolute;
      right: 0;
      bottom: -4px;
      width: 8px;
      height: 8px;
      background: #00F422;
      border-radius: 50%;
      border: 2px solid #FFFFFF;

      &.unline {
        background: #D3D3D3;
      }
    }

    .icon {
      position: absolute;
      top: -5px;
      right: -8px;
    }
  }

  .head-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;

    .name {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      padding-bottom: 3px;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }

    .order-id {
      font-size: 12px;
      color: #999999;
      margin-bottom: 2px;
    }
  }

  .time {
    font-size: 12px;
    color: #999999;
  }
}

.chat {
  flex: 1;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-bottom: 1px solid #eeeff0;
    display: flex;
    align-items: center;

    .location-info {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 27px;
        margin-right: 16px;
      }

      span {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
      }
    }

  }
  .reference-item{
    display: inline-block;
    padding-bottom: 5px;
  }

  .chat-box {
    flex: 1;
    height: 1px;
    background: #f5f6f8;
    padding: 20px;
    // width: 100%;
    height: calc(80vh - 175px);
    overflow-y: auto;
    position: relative;
  }

  .tools {
    border-bottom: 1px solid #eeeff0;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;

    img {
      width: 20px;
      cursor: pointer;
      margin-right: 40px;
    }

    .right {
      box-sizing: border-box;
      padding-right: 20px;
      font-size: 12px;
      color: #528fff;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    i {
      font-size: 26px;
      margin-right: 41px;
      cursor: pointer;
    }

    span {
      border: 1px solid #c3c3c3;
      border-radius: 3px;
      color: #666660;
      font-size: 12px;
      padding: 6px 12px;
      cursor: pointer;
      user-select: none;
      position: relative;
      top: -5px;
    }
  }

  .msg-input {
    padding: 14px;
    display: flex;
    align-items: flex-end;

    textarea {
      flex: 1;
      height: 100px;
      border: none;
      color: #333;
      user-select: none;
      outline: none;
      font-size: 14px;
    }

    .send-btn {
      width: 68px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #d8d8d8 linear-gradient(135deg, #2f88ff 0%, #43ccf8 100%);
      border-radius: 16px;
      font-size: 14px;
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      user-select: none;
    }
  }
}

.dialog-content {
  background: #f5f6f8;
  border-radius: 7px;
  padding: 20px;
}

.tips {
  display: block;
  text-align: center;
  padding-top: 80px;
  position: relative;
  bottom: -20px;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(66, 164, 97, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #333;
  //pointer-events: none;
}
</style>

<style>
.chat-page .el-button {
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 14px;
}
</style>

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

export function getPreviousDate() {
  const today = new Date(); // 获取当前日期时间
  const previousDate = new Date(today); // 使用当前日期时间创建一个新的 Date 对象

  previousDate.setDate(previousDate.getDate() - 1); // 将日期设置为一天前

  const year = previousDate.getFullYear(); // 获取年份
  const month = String(previousDate.getMonth() + 1).padStart(2, '0'); // 获取月份，月份从 0 开始，需要加 1
  const date = String(previousDate.getDate()).padStart(2, '0'); // 获取日期

  return `${year}-${month}-${date}`; // 返回格式化后的日期
}

// 获取多少天前的日期
export const getDateNDaysAgo = (daysAgo, segmentation = '-') => {
  const today = new Date();
  const before = new Date(today.getTime() - (daysAgo * 24 * 60 * 60 * 1000));
  const year = before.getFullYear();
  const month = (before.getMonth() + 1).toString().padStart(2, '0');
  const day = before.getDate().toString().padStart(2, '0');
  return `${year}${segmentation}${month}${segmentation}${day}`
}
//获取当前年月
export function getCurrentYearMonth() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month;
}
// 获取一个月有多少天
export function getDaysInMonth(yearMonth) {
  const [year, month] = yearMonth.split('-').map(Number);
  const date = new Date(year, month, 0);
  return date.getDate();
}
//计算两个日期之间的天数
export function getDaysDifference(date1, date2) {
  // 将日期字符串转换为 Date 对象
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // 计算两个日期之间的毫秒数差异
  const diffTime = Math.abs(d2 - d1);

  // 将毫秒数转换为天数
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}


// 去除字符串中的所有空格
export function removeSpaces(str) {
  if (str.trim() === "") {
    return "";
  } else {
    return str.replace(/\s/g, "");
  }
}

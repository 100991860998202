<template>
  <el-dialog
    :title="isEdit?'编辑收款码':'添加收款码'"
    :visible="true"
    width="750px"
    center
    :before-close="close"
  >
    <div class="form">
      <div class="qrcode">
        <el-upload
          :class="hideUpload?'hide':'show'"
          :file-list="fileList"
          :action="action"
          name="file"
          :headers="headers"
          :multiple="false"
          :limit="1"
          accept=".png,.jpg,.jpeg"
          list-type="picture-card"
          :on-change="fileListChange"
          :on-remove="fileListRemove"
          :before-upload="compress"
        >
          <i class="el-icon-picture-outline"></i>
        </el-upload>
        <span>上传收款码</span>
      </div>
      <div class="form-add">
        <div class="form-item">
          <span class="label">收款码名称</span>
          <el-input v-model="form.name" placeholder="请输入收款码名称" style="width: 350px"></el-input>
        </div>
        <div class="form-item">
          <span class="label">二维码种类</span>
          <el-radio-group v-model="form.code_type">
            <el-radio label="wechat">微信</el-radio>
            <el-radio label="alipay">支付宝</el-radio>
          </el-radio-group>
        </div>
        <div class="form-item">
          <span class="label">设置常用收款码</span>
          <el-radio-group v-model="form.common">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div class="form-item" v-if="form.common === 0">
          <span class="label">二维码归属地</span>
          <el-select v-model="province" clearable @change="addressChange" placeholder="请选择省份" style="width:180px; margin-right: 10px">
            <el-option
              v-for="item in addressJson"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select v-if="cityList.length" multiple collapse-tags clearable v-model="city" placeholder="请选择城市" style="width:180px">
            <el-option key="all" label="全部" value="all"></el-option>
            <el-option
              v-for="item in cityList"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirm" style="width: 200px;
background: #D8D8D8 linear-gradient(135deg, #2F88FF 0%, #43CCF8 100%); border-radius: 20px">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {BASE_URL} from "@/config"
import {mapGetters} from "vuex"
import addressJson from "@/assets/address.json"
import {addCode, updateCode} from "@/api/code"
import {compressImage} from "@/utils/imgZip";

export default {
  name: "addcode",
  props:{
    editData:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return {
      form:{
        code_type:'wechat',
        common:0,
        status:1
      },
      fileList:[],
      province:'',
      city:'',
      isEdit:false,
      hideUpload:false,
      uplodaFiles:[],
      addressJson,
      cityList:[]
    }
  },
  computed:{
    ...mapGetters(['kefuToken']),
    action(){
      return BASE_URL + "/base/upload"
    },
    headers(){
      return {
        Authorization: 'Bearer '+this.kefuToken
      }
    }
  },
  watch:{
    province(val){
      if(!val){
        this.cityList = []
      }else{
        this.addressChange(this.province)
      }
    }
  },
  mounted() {
    if(Object.keys(this.editData).length){
      this.isEdit = true
      this.fileList = [
        {
          name: this.editData.url,
          url: this.editData.url,
        }
      ]
      this.hideUpload = true
      this.form = this.editData
      if(this.editData.city === 1 && this.editData.position) {
        const province = this.editData.position[0].pid
        if(province){
          this.province = province
        }else{
          const provinceCode = this.editData.position.find(item=>item.pid === '')
          if(provinceCode !== undefined) this.province = provinceCode.id
        }
        setTimeout(()=>{
          const citys = this.editData.position.filter(item=>item.pid !== '')
          this.city = citys.map(item=>item.id)
        },1000)
      }else{
        delete this.form.position
      }
      delete this.form.updated_time
      delete this.form.created_time
      delete this.form.service_id
      delete this.form.checked
    }else{
      this.isEdit = false
    }
  },
  methods:{
    async compress(file){
      try{
        const img = await compressImage(file)
        return Promise.resolve(img)
      }catch (e) {
        return file
      }
    },
    addressChange(e){
      this.city = ''
      const cityList = this.addressJson.find(item=>item.code === e)
      if(cityList){
        this.cityList = cityList.children
      }
    },
    close(){
      this.$emit('close')
    },
    async confirm(){
      if(!this.form.name){
        this.$message({
          type: 'warning',
          message: '请输入收款码名称!'
        });
        return
      }
      if(this.isEdit){
        if(!this.fileList.length && !this.uplodaFiles.length){
          this.$message({
            type: 'warning',
            message: '请上传收款码图片!'
          });
          return
        }
      }else{
        if(!this.uplodaFiles.length){
          this.$message({
            type: 'warning',
            message: '请上传收款码图片!'
          });
          return
        }
      }
      try {
        if(this.form.common === 0){
          if(!this.province || !this.city.length){
            this.$message({
              type: 'warning',
              message: '请选择二维码归属地!'
            });
            return
          }
          this.form.city = 1
          const findAll = this.city.find(item=>item==='all')
          if(findAll !== undefined){
            const citys = this.addressJson.find(item=>item.code === this.province)
            const allCity = citys.children.map(item=>item.code+ '00')
            this.form.position = JSON.stringify(allCity)
          }else {
            const citys = this.city.map(item => item + '00')
            this.form.position = JSON.stringify([this.province + '00', ...citys])
          }
        }
        if(this.form.common === '1'){
          this.form.city = 0
        }
        if(this.isEdit){
          if(this.uplodaFiles.length){
            if(!this.uplodaFiles[0] || !this.uplodaFiles[0].response || !this.uplodaFiles[0].response.code){
              this.$message.warning("请等待图片上传完成")
              return
            }
            this.form.url = this.uplodaFiles[0].response.data.att_dir
          }else{
            this.form.url = this.fileList[0].url
          }
        }else{
          if(!this.uplodaFiles[0] || !this.uplodaFiles[0].response || !this.uplodaFiles[0].response.code){
            this.$message.warning("请等待图片上传完成")
            return
          }
          this.form.url = this.uplodaFiles[0].response.data.att_dir
        }
        console.log(this.form)
        if(this.isEdit){
          const {code} = await updateCode(this.form)
          if(code === 200){
            this.$message({
              type: 'success',
              message: '编辑成功!'
            });
            this.close()
          }else{
            this.$message({
              type: 'error',
              message: '编辑失败!'
            });
          }
        }else{
          const {code} = await addCode(this.form)
          if(code === 200){
            this.$message({
              type: 'success',
              message: '添加成功!'
            });
            this.close()
          }else{
            this.$message({
              type: 'error',
              message: '添加失败!'
            });
          }
        }
      }catch (e) {
        console.log(e)
        this.$message({
          type: 'error',
          message: e
        });
      }
    },
    fileListChange(file,fileList){
      this.uplodaFiles = fileList
      if(fileList.length >=1) this.hideUpload = true
    },
    fileListRemove(file, fileList){
      this.uplodaFiles = fileList
      if(fileList.length === 0) {
        setTimeout(()=>{
          this.hideUpload = false
        },1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form{
  display: flex;
}
.qrcode{
  display: flex;
  flex-direction: column;
  align-items: center;
  span{
    padding: 16px 0;
  }
}
.form-add{
  padding-left: 50px;
  .form-item{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    span{
      flex: 0 0 130px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>

<style>
.hide .el-upload{
  display: none;
}
.hide .el-upload–picture-card{
  display: none;
}
.show .el-upload{
  display: inline-block;
}
.show .el-upload–picture-card{
  display: block;
}
</style>

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import Vue from "vue";
import {BASE_WS_URL} from "@/config";


export const initWS = ()=>{
    return new Promise( (resolve, reject)=>{
        const imToken = localStorage.getItem('imtoken')
        if(imToken){
            Vue.use(new VueSocketIO({
                debug: false,
                connection: SocketIO( // 里面写socket服务器地址,第二个参数传自定义连接数据
                  BASE_WS_URL,
                    {
                        autoConnect: true, // 这里设置为 false
                        query: {
                            token: imToken
                        }
                    }
                ),
            }))
            resolve()
        }else{
            reject(`不满足ws连接条件,${imToken}}`)
        }
    })
}

import request from '@/utils/axios'
import {IM_HOST} from "@/config";

// 获取客服列表
export function get_kefu_list(data) {
  return request({
    url: '/Service/get_kefu_list',
    method: 'get',
    params: data,
  })
}

// 获取订单列表
export function getOrderList(data) {
  return request({
    url: '/Artificialorder/getOrderList',
    method: 'get',
    params: data,
  })
}
// 码商统计数据页面
export function getstatisticsdata(data) {
  return request({
    url: '/Databoard/getstatisticsdata',
    method: 'POST',
    data,
  })
}


// 统计对话数据
export function countTalks(data) {
  return request({
    baseURL: IM_HOST,
    headers: {
      'Content-Type': 'application/json',
      'msimToken': localStorage.getItem('imtoken') || '',
    },
    url: '/v1/payUser/countTalks',
    method: 'POST',
    data,
  })
}

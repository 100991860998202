const state = () => ({
  msgList: {},
  msgDot: [],
  waitCheckMsg: [],
  signNotification: false,
})

const getters = {
  signNotification: (state) => state.signNotification,
  getUserChatList: (state)=>(talk_id)=>{
      const userChatList = state.msgList[talk_id]
      if(userChatList && userChatList.length){
        return userChatList
      }else{
        return []
      }
  }
}

const mutations = {

  setsignNotification(state, status) {
    state.signNotification = status
  },
  addMsg(state, msg) {
    state.msgList.push(msg)
  },
  // 该列表只做最新未读消息，只保留最新一条数据
  addwaitCheckMsg(state, msg) {
    const check = state.waitCheckMsg.findIndex((item) => item.uid === msg.uid)
    if (check !== -1) {
      state.waitCheckMsg.splice(check, 1, msg)
    } else {
      state.waitCheckMsg.push(msg)
    }
  },
  clearMsg(state) {
    state.msgList = []
  },
  setMsgDot(state, uid) {
    const checkUidIndex = state.msgDot.findIndex((item) => item.uid === uid)
    if (checkUidIndex === -1) {
      state.msgDot.push({ uid, count: 1 })
    } else {
      state.msgDot.splice(checkUidIndex, 1, {
        uid,
        count: (state.msgDot[checkUidIndex].count += 1),
      })
    }
  },
  clearDot(state, uid = null) {
    if (uid === null) {
      state.msgDot = []
      return
    }
    const find = state.msgDot.findIndex((item) => item.uid === uid)
    if (find !== -1) {
      state.msgDot.splice(find, 1)
    }
  },
}

export default { namespace: true, state, getters, mutations }

<template>
  <div id="app">
    <task-time/>
    <router-view />
  </div>
</template>

<script>
  import { mapActions, mapMutations} from 'vuex'
  import localforage from "localforage";
  import taskTime from "@/components/taskTime.vue";

  export default {
    components:{taskTime},
    created() {
      this.resetNoReadNum()
      this.reset()
    },
    methods: {
      ...mapActions(['getUserInfo']),
      ...mapMutations('chat',['resetUserNoReadKeys', 'resetMyNoReadMsgKeys','resetMsgList']),
      resetNoReadNum(){
        localforage.getItem('noreadMsgKeys').then(res=>{
          console.log('noreadMsgKeys localforage',res)
          if(res) this.resetMyNoReadMsgKeys(res)
        })
        localforage.getItem('noReadList').then(res=>{
          console.log('noReadList localforage',res)
          if(res) this.resetUserNoReadKeys(res)
        })
      },
      reset(){
        localforage.getItem('imMsgList').then(res=>{
          console.log('imMsgList localforage',res)
          if(res) this.resetMsgList(res)
        })
      }
    },
  }
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
  }
</style>

<template>
  <el-dialog
    title="修改资料"
    center
    :visible="dialogVisible"
    width="500px"
    @close="$emit('close')"
   >
    <div class="change-form">
      <el-form :rules="rules" ref="ruleForm" :model="form" label-width="80px">
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :on-success="onSuccess"
          >
            <img :src="avatar" alt="" style="width: 50px; height: 50px; border-radius: 25px;">
          </el-upload>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="account" disabled ></el-input>
        </el-form-item>
        <el-form-item label=" " prop="account">
          <span style="color: #2F88FF; cursor: pointer" @click="changePwdStatus">修改密码</span>
        </el-form-item>
        <el-form-item label="旧密码" prop="old_password" v-if="changePwd">
          <el-input v-if="changePwd" v-model="form.old_password" style="width: 200px; margin-right: 10px" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" v-if="changePwd" prop="password">
          <el-input v-model="form.password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" v-if="changePwd" prop="confirm">
          <el-input v-model="form.confirm" placeholder="请确认新密码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
 import {mapState} from "vuex";
 import {BASE_URL} from "@/config";
 import {serviceupdate} from "@/api/login";
 import {mapActions} from "vuex"

 export default {
   name:"ChnageUser",
   data(){
     var validatePass2 = (rule, value, callback) => {
       if (value === '') {
         callback(new Error('请再次输入密码'));
       } else if (value !== this.form.password) {
         callback(new Error('两次输入密码不一致!'));
       } else {
         callback();
       }
     };
     return {
       dialogVisible:true,
       form:{},
       account:'',
       avatar:'',
       changePwd:false,
       action:'',
       headers:{},
       rules:{
         nickname: [
           { required: true, message: '请输入昵称', trigger: 'change' }
         ],
         old_password: [
           { required: true, message: '请输入旧密码', trigger: 'change' }
         ],
         password: [
           { required: true, message: '请输入新密码', trigger: 'change' }
         ],
         confirm: [
           { validator: validatePass2, trigger: 'change' }
         ],
       }
     }
   },
   computed:{
     ...mapState(['userInfo']),
   },
   mounted() {
     this.action = BASE_URL + '/base/upload'
     const token = localStorage.getItem('token')
     this.headers = {
       'Authorization': `Bearer ${token}`
     }
     this.$set(this.form, 'avatar',  this.userInfo.avatar)
     this.$set(this.form, 'nickname',  this.userInfo.nickname)
     this.account = this.userInfo.account
     this.avatar = this.userInfo.avatar || require('@/assets/logo.png')
   },
   methods:{
     ...mapActions(['getUserInfo']),
     changePwdStatus(){
       this.changePwd = !this.changePwd
       if(!this.changePwd){
         delete this.form.old_password
         delete this.form.password
         delete this.form.confirm
       }
     },
     onSuccess(response){
       if(response.code === '200'){
         this.$set(this.form, 'avatar',  response.data.att_dir)
         this.avatar = response.data.att_dir
       }
     },
     submit(){
       this.$refs.ruleForm.validate(async (valid) => {
         if (valid) {
           const res = await serviceupdate(this.form)
           console.log(res)
           if(res.code === 200){
             await this.getUserInfo()
             this.$message.success('修改资料成功！')
             this.$emit('close')
           }
         }
       });
     }
   }
 }
</script>

<style scoped lang="scss">

</style>
